import { Close, Home, House, NetworkCell, OpenInNew } from '@mui/icons-material';
import PrintIcon from '@mui/icons-material/Print';
import { Box, Button, Card, CardHeader, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { BarChart, PieChart } from '@mui/x-charts';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import {
  cheerfulFiestaPalette,
  mangoFusionPalette
} from '@mui/x-charts/colorPalettes';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import useLocales from '../../../hooks/useLocales';
import { getForm, getJourney, getPremises, removeFloors, removePremise, setFloors, setPremises } from '../../../redux/slices/deal';
import { useDispatch, useSelector } from '../../../redux/store';
import boulevard from './Boulevard.png';
import ConfigGrid from './ConfigGrid';
import ManagementTool from './ManagementTool';
import { OpinionAnswer, AnswerList, NumberAnswer } from '../survey';
import { CostCalculator } from '../simulators';
// ----------------------------------------------------------------------
const dataset = [
  {
    seoul: 21,
    activity: 'Kollaborativt',
  },
  {
    seoul: -12,
    activity: 'Eget arbete',
  },
  {
    seoul: -41,
    activity: 'Fokus',
  },
  {
    seoul: 33,
    activity: 'Event',
  },
];
const scenarios = [{
  id: "Ankaret",
  data: [
    { id: 0, value: 30, label: 'HQ' },
    { id: 1, value: 15, label: 'Satellit' },
    { id: 2, value: 10, label: 'Co-work' },
    { id: 3, value: 20, label: 'Hem' }
  ]
}, {
  id: "Hub & Spoke",
  data: [
    { id: 0, value: 20, label: 'HQ' },
    { id: 1, value: 50, label: 'Satellit' },
    { id: 2, value: 10, label: 'Co-work' },
    { id: 3, value: 20, label: 'Hem' }
  ]
}, {
  id: "Distribuerat",
  data: [
    { id: 0, value: 0, label: 'HQ' },
    { id: 1, value: 10, label: 'Satellit' },
    { id: 2, value: 50, label: 'Co-work' },
    { id: 3, value: 40, label: 'Hem' }
  ]
},


]
const series = [{
  data: [-2, -9, 12, 11, 6, -4],
  valueFormatter: (v, { dataIndex }) => {
    return `${v} platser mot behov`;
  },
}];
const labels = ['Samarbete', 'Egete arbete', 'Fokus', 'Event'];
const actions = [
  { icon: <House />, name: 'HQ' },
  { icon: <NetworkCell />, name: 'Satellite' },
  { icon: <PrintIcon />, name: 'Co-Work' },
  { icon: <Home />, name: 'Home' },
];

const settings = {
  height: 150,
  value: 62,
};


const ContentStyle = styled((props) => <Stack spacing={0} {...props} />)(({ theme }) => ({
  zIndex: 10,
  margin: 'auto',
  width: '100%',
  textAlign: 'center',
  position: 'relative',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingBottom: theme.spacing(5)
}));


const LOW_ARRAY = [17, 18, 19, 25];
const MID_ARRAY = [14, 16, 17, 23];
const HIGH_ARRAY = [11, 14, 15, 20];
// ----------------------------------------------------------------------

export default function WorkplacerDashboard({ percentageArray, workplaces }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { journey, form, premises, isLoadingPremises } = useSelector((state) => state.site);
  const { place } = useSelector((state) => state.place);
  const [field, setField] = useState(0);
  const [value, setValue] = useState(0);
  const [valueS, setValueS] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [inputOpen, setInputOpen] = useState(true);
  const [recOpen, setRecOpen] = useState(true);
  const [solOpen, setSolOpen] = useState(true);
  const [impactOpen, setImpactOpen] = useState(true);
  const [density, setDensity] = useState(journey?.density || 'Mid');
  const [sqm, setSqm] = useState(2000);
  const [sqmNeed, setSqmNeed] = useState(2000);
  const [employees, setEmployees] = useState(workplaces || 100);
  const [growth, setGrowth] = useState(journey?.growth || 5);
  const [sick, setSick] = useState(journey?.sick || 5);
  const [absence, setAbsence] = useState(journey?.absence || 5);
  const [travel, setTravel] = useState(journey?.travel || 5);
  const [wfh, setWfh] = useState(journey?.travel || 0);
  const [other, setOther] = useState(0);
  const [open, setOpen] = useState(journey?.openperc || 40);
  const [shared, setShared] = useState(journey?.sharedperc || 20);
  const [sharedOpen, setSharedOpen] = useState(journey?.sharedopenperc || 30);
  const [rooms, setRooms] = useState(journey?.roomsperc || 10);
  const [percentage, setPercentage] = useState(100);
  const [wfhPolicy, setWfhPolicy] = useState(journey?.wfhpolicy || 'Behov');

  async function getAns(id, fields) {
    setIsLoad(true);
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/journeys/answers/${id}`,
      { params: { fields: fields } });
    setAnswers(response.data.items);
    setIsLoad(false);
  }
  function fieldModule(field) {
    console.log('field type', field.type);
    switch (field.type) {
      case 'short_text':
        return (
          <AnswerList />
        )
      case 'number':
        return (
          <Box sx={{ height: '50vh' }}>
            <NumberAnswer responses={answers} />
          </Box>
        )
      case 'opinion_scale':
        return (
          <Box sx={{ height: '50vh' }}>
            <OpinionAnswer responses={answers} />
          </Box>
        )
      default:
        return (
          <Box sx={{ height: '50vh' }}>
            <AnswerList />
          </Box>
        );
    }
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  useEffect(() => {
    calculateSpace();
    setPercentage(shared + open + rooms + sharedOpen);
  });

  useEffect(() => {
    if (journey) return;
    dispatch(getJourney(1));
  }, [dispatch]);

  useEffect(() => {
    if (!journey) return;
    console.log('jo', journey);
    setOpen(journey.openperc);
    setShared(journey.sharedperc);
    setSharedOpen(journey.sharedopenperc);
    setWfh(journey.roomsperc);
    setDensity(journey.density);
    setWfhPolicy(journey.wfhpolicy);
    setGrowth(journey.growth || 0);
    setSick(journey.sick || 0);
    setAbsence(journey.absence || 0);
    setTravel(journey.travel || 0);
    setEmployees(journey.employees || 0);
    dispatch(getPremises(journey.id));
    if (!journey.form_id) return;
    dispatch(getForm(journey.id));
  }, [dispatch, journey]);

  function densitySwitch(density) {
    switch (density) {
      case 'high':
        return HIGH_ARRAY
      case 'Mid':
        return MID_ARRAY
      case 'Low':
        return LOW_ARRAY
      default:
        return MID_ARRAY
    }
  }
  function calculateSpace() {

    const array = densitySwitch(density);
    const weightedSqmWorkplace = (open * array[0] + sharedOpen * array[1] + shared * array[2] + rooms * array[3]) / 100;
    const multiplier = 1 + (growth - travel - absence - sick) / 100;
    const wfhMultiplier = wfhPolicy === 'Peak' ? 1 : 1 - (wfh / 100);
    const sqmNeed = Math.round(weightedSqmWorkplace * employees * multiplier * wfhMultiplier);
    const potentialSqm =
      sqmNeed < 1.2 * sqm ? Math.round(Math.max(sqm - sqmNeed, 0)) : Math.round(Math.max(sqmNeed - sqm, 0));
    setSqmNeed(sqmNeed);
    return sqmNeed
  }
  const lightIconColor =
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';

  const colorScaleUp = [theme.palette.error.dark, theme.palette.warning.dark, theme.palette.warning.main, theme.palette.success.light, theme.palette.success.main, theme.palette.success.dark, theme.palette.success.dark, theme.palette.success.darker, theme.palette.success.darker, theme.palette.primary.dark, theme.palette.primary.darker];
  function ltvColor(ltv) {
    if (ltv > 0) {
      return theme.palette.success.main
    } else if (ltv < 0) {
      return theme.palette.error.main
    } else {
      return lightIconColor
    }
  }


  const handleDown = () => {
    // dispatch(getAnswers(journey.id, fields[value].properties.fields[field - 1].id));
    setField(Math.max(0, field - 1));
  };

  const handleUp = () => {
    getAns(journey.id, fields[value].properties.fields[field + 1].id);
    // dispatch(getAnswers();
    setField(Math.min(field + 1, Math.ceil(fields[value].properties.fields.length) - 1));
  };
  const flatten = (array) =>
    array.reduce(
      (results, item) => [
        ...results,
        ...item.floors.map((floor) => ({ id: item.id, floor_id: floor.id, floor_sqm: floor.sqm }))
      ],
      []
    );
  const handleAddPremise = (format) => {
    console.log('setting', premises);

    const flatPremises = flatten(premises);
    const neededSpace = sqmNeed;
    console.log('flatpremi', flatPremises);
    const allocatedSpace = flatPremises.reduce((a, v) => (a += v.floor_sqm), 0);
    const sqmRemaining = Math.max(0, (neededSpace - allocatedSpace));
    dispatch(setPremises(journey, format, sqmRemaining));
  };

  const handleFloor = (id) => {
    console.log('idx', id);
    const premise = premises[id]
    console.log('floor', premise);
    const allocatedSpace = premise.floors.reduce((a, v) => (a += v.sqm), 0);
    const sqmRemaining = Math.max(0, (premise.sqm - allocatedSpace));
    console.log('setting');
    dispatch(setFloors(journey, premise.id, sqmRemaining));
  };

  const handleRemoveFloor = (id) => {
    dispatch(removeFloors(id));
  };

  const handleRemovePremise = (id) => {
    dispatch(removePremise(id));
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeS = (event, newValue) => {
    setValueS(newValue);
  };

  async function updateFloorSqm(floor, newValue) {
    console.log('new sqm', floor, newValue);
  }

  const { fields, last_updated_at } = form;
  const openness = Math.floor(12 / [inputOpen, recOpen, solOpen, impactOpen].filter((x) => x === true).length);
  console.log('journey', journey);
  //RENDER
  return (
    <ContentStyle>
      <Grid container spacing={1} >
        <Grid item xs={3}>
          <Card sx={{ borderRadius: 0, mb: 3, p: 0, backgroundColor: inputOpen ? '#66bb6a' : '#f44336' }}>
            <Button sx={{ width: '100%' }} onClick={() => setInputOpen(!inputOpen)}>
              <Stack spacing={2} direction="row" sx={{ width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                {inputOpen ? (
                  <Close />
                ) : (
                  <OpenInNew />
                )}
                <Typography gutterBottom variant="h4" sx={{ mb: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
                  Input
                </Typography>
              </Stack>
            </Button>
          </Card>
        </Grid>


        <Grid item xs={3}>
          <Card sx={{ borderRadius: 0, mb: 3, p: 0, backgroundColor: recOpen ? '#66bb6a' : '#f44336' }}>
            <Button sx={{ width: '100%' }} onClick={() => setRecOpen(!recOpen)}>
              <Stack spacing={2} direction="row" sx={{ width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                {recOpen ? (
                  <Close />
                ) : (
                  <OpenInNew />
                )}
                <Typography gutterBottom variant="h4" sx={{ mb: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
                  Recommendation
                </Typography>
              </Stack>
            </Button>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card sx={{ borderRadius: 0, mb: 3, p: 0, backgroundColor: solOpen ? '#66bb6a' : '#f44336' }}>
            <Button sx={{ width: '100%' }} onClick={() => setSolOpen(!solOpen)}>
              <Stack spacing={2} direction="row" sx={{ width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                {solOpen ? (
                  <Close />
                ) : (
                  <OpenInNew />
                )}
                <Typography gutterBottom variant="h4" sx={{ mb: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
                  Solution
                </Typography>
              </Stack>
            </Button>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card sx={{ borderRadius: 0, mb: 3, p: 0, backgroundColor: impactOpen ? '#66bb6a' : '#f44336' }}>
            <Button sx={{ width: '100%' }} onClick={() => setImpactOpen(!impactOpen)}>
              <Stack spacing={2} direction="row" sx={{ width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                {impactOpen ? (
                  <Close />
                ) : (
                  <OpenInNew />
                )}
                <Typography gutterBottom variant="h4" sx={{ mb: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
                  Effekt
                </Typography>
              </Stack>
            </Button>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        {inputOpen && (
          <Grid item xs={openness}>

            <Stack spacing={1}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h4" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                  {translate('Input').toUpperCase()}
                </Typography>
              </Grid>
              <Card>
                <Grid container spacing={0}>

                  {form && (
                    <Grid item xs={12}>
                      <Card sx={{ height: '100%', alignItems: 'flex-end', alignContent: 'flex-end', borderRadius: 0, pb: 2, backgroundColor: '#fff', width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Stack spacing={0}>

                          <Gauge
                            {...settings}
                            cornerRadius="50%"
                            startAngle={-90}
                            endAngle={90}
                            sx={(theme) => ({
                              [`& .${gaugeClasses.valueText}`]: {
                                fontSize: 30,
                              },
                              [`& .${gaugeClasses.valueArc}`]: {
                                fill: '#52b202',
                              },
                              [`& .${gaugeClasses.referenceArc}`]: {
                                fill: theme.palette.text.disabled,
                              },
                            })}
                          />
                          <CardHeader subheader={`${Math.round(62 / (62 + 38) * 100)} % response rate`} />

                        </Stack>
                      </Card>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <Card sx={{ height: '100%', alignItems: 'flex-end', alignContent: 'flex-end', borderRadius: 0, p: 2, backgroundColor: '#e15759' }}>
                      <Box sx={{ alignContent: 'center', alignItems: 'center', height: '50%' }}>

                        <Typography gutterBottom variant="h3" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                          Ind. Arbete
                        </Typography>
                      </Box>
                      Välfungerande
                      <Typography gutterBottom variant="h4" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                        Aktivitet
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card sx={{ height: '100%', alignItems: 'flex-end', alignContent: 'flex-end', borderRadius: 0, p: 2, backgroundColor: '#76b7b2' }}>
                      <Box sx={{ alignContent: 'center', alignItems: 'center', height: '50%' }}>
                        <Typography gutterBottom variant="h3" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                          3/5
                        </Typography>
                      </Box>
                      Dagar

                      <Typography gutterBottom variant="h4" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                        Närvaro
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card sx={{ height: '100%', alignItems: 'flex-end', alignContent: 'flex-end', borderRadius: 0, p: 2, backgroundColor: '#4e79a7' }}>
                      <Box sx={{ alignContent: 'center', alignItems: 'center', height: '50%' }}>

                        <Typography gutterBottom variant="h3" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                          Dig. Möten
                        </Typography>
                      </Box>
                      Prioriterad
                      <Typography gutterBottom variant="h4" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                        Aktivitet
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card sx={{ height: '100%', alignItems: 'flex-end', alignContent: 'flex-end', borderRadius: 0, p: 2, backgroundColor: '#f28e2c' }}>
                      <Box sx={{ alignContent: 'center', alignItems: 'center', height: '50%' }}>
                        <Typography gutterBottom variant="h3" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                          Må,Ti,On
                        </Typography>
                      </Box>
                      Dagar

                      <Typography gutterBottom variant="h4" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                        Veckodagar
                      </Typography>
                    </Card>
                  </Grid>
                </Grid>
              </Card>
              <Card sx={{ p: 2, backgroundColor: '#fff' }}>
                <ManagementTool
                  onWfhPolicy={setWfhPolicy}
                  wfhPolicy={wfhPolicy}
                  onDensity={setDensity}
                  density={density}
                  onGrowth={setGrowth}
                  growth={growth}
                  onEmployees={setEmployees}
                  employees={employees}
                  onAbsence={setAbsence}
                  absence={absence}
                  onSick={setSick}
                  sick={sick}
                  onTravel={setTravel}
                  travel={travel}
                  onWfh={setWfh}
                  wfh={wfh}
                  open={open}
                  onOpen={setOpen}
                  sharedOpen={sharedOpen}
                  onSharedOpen={setSharedOpen}
                  rooms={rooms}
                  onRooms={setRooms}
                  shared={shared}
                  onShared={setShared}
                  sqm={sqm}
                  percentage={percentage}
                />

              </Card>
            </Stack>
          </Grid>
        )}
        {recOpen && (

          <Grid item xs={openness}>

            <Stack spacing={1}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h4" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                  {translate('Recommendation').toUpperCase()}
                </Typography>
              </Grid>
              <Card sx={{ borderRadius: 0, pt: 2 }}>
                <Tabs
                  value={valueS}
                  onChange={handleChangeS}
                  aria-label="Vertical tabs example"
                  sx={{ borderBottom: 2, borderColor: 'divider', height: '100%', width: '100%', mb: 2 }}
                  centered
                >
                  <Tab label={'Scenario 1'} {...a11yProps(0)} />
                  <Tab label={'Scenario 2'} {...a11yProps(1)} />
                  <Tab label={'Scenario 3'} {...a11yProps(2)} />
                </Tabs>

                <TabPanel value={valueS} index={0}>

                  <Typography variant="h4" sx={{ color: 'text.primary' }}>
                    {translate('Hybrid Profile').toUpperCase()}
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'text.primary' }}>
                    {translate(scenarios[0].id).toUpperCase()}
                  </Typography>


                  <PieChart
                    series={[
                      {
                        data: scenarios[0].data,
                        valueFormatter: (v, { dataIndex }) => {
                          return `${v.value} %`;
                        },
                      },
                    ]}
                    height={200}
                  />
                  <Typography variant="h3" sx={{ color: 'text.primary' }}>
                    {sqmNeed} sqm
                  </Typography>
                  <Typography variant="h5" sx={{ color: 'success' }}>
                    Relevance: 75%
                  </Typography>
                  <ConfigGrid scenario={scenarios[valueS]} wfh={wfh} sqmNeed={sqmNeed} />

                </TabPanel>

                <TabPanel value={valueS} index={1}>
                  <Typography variant="h4" sx={{ color: 'text.primary' }}>
                    {translate('Hybrid Profile').toUpperCase()}
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'text.primary' }}>
                    {translate(scenarios[1].id).toUpperCase()}
                  </Typography>


                  <PieChart
                    series={[
                      {
                        data: scenarios[1].data,
                        valueFormatter: (v, { dataIndex }) => {
                          return `${v.value} %`;
                        },
                      },
                    ]}
                    height={200}
                  />
                  <Typography variant="h3" sx={{ color: 'text.primary' }}>
                    {sqmNeed} sqm
                  </Typography>
                  <Typography variant="h5" sx={{ color: 'warning' }}>
                    Relevance: 50%
                  </Typography>
                  <ConfigGrid scenario={scenarios[valueS]} wfh={wfh} sqmNeed={sqmNeed} />
                </TabPanel>
                <TabPanel value={valueS} index={2}>
                  <Typography variant="h4" sx={{ color: 'text.primary' }}>
                    {translate('Hybrid Profile').toUpperCase()}
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'text.primary' }}>
                    {translate(scenarios[2].id).toUpperCase()}
                  </Typography>


                  <PieChart
                    series={[
                      {
                        data: scenarios[2].data,
                        valueFormatter: (v, { dataIndex }) => {
                          return `${v.value} %`;
                        },
                      },
                    ]}
                    height={200}
                  />
                  <Typography variant="h3" sx={{ color: 'text.primary' }}>
                    {sqmNeed} sqm
                  </Typography>
                  <Typography variant="h5" sx={{ color: 'text.primary' }}>
                    Relevance: 30%
                  </Typography>

                  <ConfigGrid scenario={scenarios[valueS]} wfh={wfh} sqmNeed={sqmNeed} />
                </TabPanel>


              </Card>

              <Card sx={{ borderRadius: 0, pt: 2 }}>

                <Typography variant="h4" sx={{ color: 'text.primary' }}>
                  {translate('Configuration').toUpperCase()}
                </Typography>
                <Typography variant="caption" sx={{ color: 'text.primary' }}>
                  {translate('The Boulevard').toUpperCase()}
                </Typography>
                <Box
                  component="img"
                  alt="City image"
                  src={boulevard}
                  sx={{ height: '100%', px: '5', borderRadius: 1.5, my: 3, px: 3 }}
                />
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderBottom: 2, borderColor: 'divider', height: '100%', width: '100%', mb: 2 }}
                  centered
                >
                  <Tab label={'Aktiviteter'} {...a11yProps(0)} />
                  <Tab label={'Sound'} {...a11yProps(1)} />
                  <Tab label={'Åtkomst'} {...a11yProps(2)} />
                </Tabs>

                <TabPanel value={value} index={0}>
                  <PieChart
                    colors={mangoFusionPalette}
                    series={[
                      {
                        data: [
                          { id: 0, value: 10, label: 'Kollab.' },
                          { id: 1, value: 45, label: 'Ind. Arb.' },
                          { id: 2, value: 20, label: 'Fokus' },
                          { id: 3, value: 30, label: 'Event' }
                        ],
                        valueFormatter: (v, { dataIndex }) => {
                          return `${v.value} %`;
                        },
                      },
                    ]}
                    height={200}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <PieChart
                    series={[
                      {
                        data: [
                          { id: 0, value: 10, label: 'Tyst' },
                          { id: 1, value: 45, label: 'Semityst' },
                          { id: 2, value: 20, label: 'Levande' },
                          { id: 3, value: 30, label: 'Event' }
                        ],
                        valueFormatter: (v, { dataIndex }) => {
                          return `${v.value} %`;
                        },
                      },
                    ]}
                    height={200}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <PieChart
                    colors={cheerfulFiestaPalette}
                    series={[
                      {
                        data: [
                          { id: 0, value: 5, label: 'Sekretess' },
                          { id: 1, value: 100, label: 'Privat' },
                          { id: 2, value: 20, label: 'Semiprivat' },
                          { id: 3, value: 10, label: 'Publikt' }
                        ],
                        valueFormatter: (v, { dataIndex }) => {
                          return `${v.value} %`;
                        },
                      },
                    ]}
                    height={200}
                  />
                </TabPanel>

              </Card>
            </Stack>
          </Grid>
        )}
        {solOpen && (

          <Grid item xs={openness}>

            <Stack>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h4" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                  {translate('Solution').toUpperCase()}
                </Typography>
              </Grid>
              <Grid container spacing={1} sx={{ my: 0 }}>

                <Grid item xs={6}>
                  <Card sx={{ height: '100%', alignItems: 'flex-end', alignContent: 'flex-end', borderRadius: 0, p: 2, backgroundColor: sqmNeed > flatten(premises).reduce((a, v) => (a += v.floor_sqm), 0) ? 'secondary.lighter' : '#ccebc5' }}>
                    <Box sx={{ alignContent: 'center', alignItems: 'center', height: '50%' }}>
                      <Typography gutterBottom variant="h3" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                        {Math.abs(sqmNeed - flatten(premises).reduce((a, v) => (a += v.floor_sqm), 0))}
                      </Typography>
                      <Typography gutterBottom variant="h5" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                        {sqmNeed < flatten(premises).reduce((a, v) => (a += v.floor_sqm), 0) ? `sqm över` : `sqm saknas`}
                      </Typography>
                    </Box>
                    {sqmNeed > flatten(premises).reduce((a, v) => (a += v.floor_sqm), 0) ? 'Small' : 1 < 0 ? 'Mid' : 'Large'}

                    <Typography gutterBottom variant="h4" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                      Storlek
                    </Typography>
                  </Card>
                </Grid>

                <Grid item xs={6}>
                  <Card sx={{ borderRadius: 0, p: 1 }}>
                    <BarChart
                      width={200}
                      layout='horizontal'
                      grid={{ horizontal: true }}
                      height={200}
                      yAxis={[{ data: labels, scaleType: 'band' }]}
                      series={series}
                      xAxis={[
                        {
                          colorMap:
                            ('a' === 'a' && {
                              type: 'continuous',
                              min: -20,
                              max: 20,
                              color: ['red', 'green'],
                            })
                        },
                      ]}

                    />
                    {sqmNeed > flatten(premises).reduce((a, v) => (a += v.floor_sqm), 0) ? 'Small' : 1 < 0 ? 'Mid' : 'Large'}
                    <Typography gutterBottom variant="h4" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                      Aktivitet
                    </Typography>
                  </Card>
                </Grid>

                <Grid item xs={6}>
                  <Card sx={{ borderRadius: 0, p: 1 }}>
                    <BarChart
                      width={200}
                      layout='horizontal'
                      grid={{ horizontal: true }}
                      height={200}
                      yAxis={[{ data: labels, scaleType: 'band' }]}
                      series={series}
                      xAxis={[
                        {
                          colorMap:
                            ('a' === 'a' && {
                              type: 'continuous',
                              min: -20,
                              max: 20,
                              color: ['red', 'green'],
                            })
                        },
                      ]}

                    />
                    {sqmNeed > flatten(premises).reduce((a, v) => (a += v.floor_sqm), 0) ? 'Small' : 1 < 0 ? 'Mid' : 'Large'}
                    <Typography gutterBottom variant="h4" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                      Sound
                    </Typography>
                  </Card>
                </Grid>

                <Grid item xs={6}>
                  <Card sx={{ borderRadius: 0, p: 1 }}>
                    <BarChart
                      width={200}
                      layout='horizontal'
                      grid={{ horizontal: true }}
                      height={200}
                      yAxis={[{ data: labels, scaleType: 'band' }]}
                      series={series}
                      xAxis={[
                        {
                          colorMap:
                            ('a' === 'a' && {
                              type: 'continuous',
                              min: -20,
                              max: 20,
                              color: ['red', 'green'],
                            })
                        },
                      ]}

                    />
                    {sqmNeed > flatten(premises).reduce((a, v) => (a += v.floor_sqm), 0) ? 'Small' : 1 < 0 ? 'Mid' : 'Large'}
                    <Typography gutterBottom variant="h4" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                      Format
                    </Typography>
                  </Card>
                </Grid>

              </Grid>

            </Stack>
          </Grid>
        )}
        {impactOpen && (

          <Grid item xs={openness}>

            <Stack>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h4" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                  {translate('Effects').toUpperCase()}
                </Typography>
              </Grid>


              <Grid container spacing={1} sx={{ my: 0 }}>

                <Grid item xs={6}>
                  <Card sx={{ height: '100%', alignItems: 'flex-end', alignContent: 'flex-end', borderRadius: 0, p: 1, }}>
                    <Box sx={{ alignContent: 'center', alignItems: 'center', height: '50%' }}>
                      <Typography gutterBottom variant="h3" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                        2,2
                      </Typography>
                      <Typography gutterBottom variant="h5" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                        mkr/år
                      </Typography>
                    </Box>
                    {sqmNeed > flatten(premises).reduce((a, v) => (a += v.floor_sqm), 0) ? 'Small' : 1 < 0 ? 'Mid' : 'Large'}

                    <Typography gutterBottom variant="h4" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                      Cost
                    </Typography>
                  </Card>
                </Grid>

                <Grid item xs={6}>
                  <Card sx={{ borderRadius: 0, p: 1, backgroundColor: 'primary.lighter' }}>
                    <BarChart
                      width={200}
                      layout='horizontal'
                      grid={{ horizontal: true }}
                      height={200}
                      yAxis={[{ data: labels, scaleType: 'band' }]}
                      series={series}
                      xAxis={[
                        {
                          colorMap:
                            ('a' === 'a' && {
                              type: 'continuous',
                              min: -20,
                              max: 20,
                              color: ['red', 'green'],
                            })
                        },
                      ]}

                    />
                    {sqmNeed > flatten(premises).reduce((a, v) => (a += v.floor_sqm), 0) ? 'Small' : 1 < 0 ? 'Mid' : 'Large'}
                    <Typography gutterBottom variant="h4" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                      Investering
                    </Typography>
                  </Card>
                </Grid>

                <Grid item xs={6}>
                  <Card sx={{ borderRadius: 0, p: 1 }}>
                    <BarChart
                      width={200}
                      layout='horizontal'
                      grid={{ horizontal: true }}
                      height={200}
                      yAxis={[{ data: labels, scaleType: 'band' }]}
                      series={series}
                      xAxis={[
                        {
                          colorMap:
                            ('a' === 'a' && {
                              type: 'continuous',
                              min: -20,
                              max: 20,
                              color: ['red', 'green'],
                            })
                        },
                      ]}

                    />
                    {sqmNeed > flatten(premises).reduce((a, v) => (a += v.floor_sqm), 0) ? 'Small' : 1 < 0 ? 'Mid' : 'Large'}
                    <Typography gutterBottom variant="h4" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                      Restid
                    </Typography>
                  </Card>
                </Grid>

                <Grid item xs={6}>
                  <Card sx={{ borderRadius: 0, p: 1 }}>
                    <BarChart
                      width={200}
                      layout='horizontal'
                      grid={{ horizontal: true }}
                      height={200}
                      yAxis={[{ data: labels, scaleType: 'band' }]}
                      series={series}
                      xAxis={[
                        {
                          colorMap:
                            ('a' === 'a' && {
                              type: 'continuous',
                              min: -20,
                              max: 20,
                              color: ['red', 'green'],
                            })
                        },
                      ]}

                    />
                    {sqmNeed > flatten(premises).reduce((a, v) => (a += v.floor_sqm), 0) ? 'Small' : 1 < 0 ? 'Mid' : 'Large'}
                    <Typography gutterBottom variant="h4" sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                      Närvaro
                    </Typography>
                  </Card>
                </Grid>

              </Grid>
              <Card sx={{ borderRadius: 0, p: 1, my: 1 }}>

                <CostCalculator workplaces={employees} wfhd={wfh} />
              </Card>
            </Stack>
          </Grid>
        )}
      </Grid >
    </ContentStyle >
  );
}


/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts
import { AddBusiness, AttachMoney, Bento, Book, Carpenter, DeliveryDining, DocumentScanner, Feed, ForkRight, Home, Insights, Lightbulb, ManageSearch, Settings } from "@mui/icons-material";
import Office from "./examples/Icons/Office";
import Shop from "./examples/Icons/Shop";
import ProductPage from "./layouts/products/product-page";

const routes = [


  { type: "title", title: "Portfolio", key: "overview-title" },
  {
    type: "direct",
    name: "Home",
    key: "controlpanel",
    href: "/controlpanel",
    component: <ProductPage />,
    icon: <Home size="12px" />
  }, 
  {
    type: "direct",
    name: "Portfolio",
    key: "portfoliooverview",
    href: "/portfolio/overview",
    component: <ProductPage />,
    icon: <Book size="12px" />
  }, 

  { type: "divider", key: "divider-1j" },

  { type: "title", title: "Project", key: "journey-title" },
/*   {
    type: "direct",
    component: <ProductPage />,
    icon: <Feed size="12px" />,
    name: "Discover",
    key: "surveysurvey",
    href: "/survey/survey",
  }, */
  {
    type: "direct",
    component: <ProductPage />,
    icon: <Feed size="12px" />,
    name: "Discover",
    key: "journeydiscover",
    href: "/journey/discover",
  },
  {
    type: "direct",
    name: "Define",
    key: "journeydefine",
    href: "/journey/define",
    component: <ProductPage />,
    icon: <Insights size="12px" />
  },
  {
    type: "direct",
    name: "Design",
    key: "journeydesign",
    isSwitch: true,
    href: "/journey/design",
    component: <ProductPage />,
    icon: <Bento size="12px" />,
  },
  {
    type: "direct",
    name: "Decide",
    key: "journeydecide",
    isSwitch: true,
    href: "/journey/decide",
    icon: <ForkRight size="12px" />,
  },/* 
  {
    type: "direct",
    name: "Search ",
    key: "journeysearch",
    isSwitch: true,
    href: "journey/search",
    icon: <ManageSearch size="12px" />,
  }, */

  { type: "divider", key: "divider-1a" },

  { type: "title", title: "Search", key: "search-title" },
  {
    type: "direct",
    name: "Marketplacer",
    key: "journeymarketplacer",
    isSwitch: true,/* 
    isFuture: true, */
    href: "/journey/marketplacer",
    
    component: <ProductPage />,
    icon: <AddBusiness size="12px" />,
  },
  
  { type: "divider", key: "divider-1c" },

  { type: "title", title: "Change", key: "implement-title" },/* 
  {
    type: "direct",
    name: "Premises Evaluation",
    key: "projectsscenario-simulator",
    isSwitch: true, 
    href: "/projects/scenario-simulator",
    component: <ProductPage />,
    icon: <BusinessCenter size="12px" />,
  }, */
  /* {
    type: "direct",
    name: "Offer & Agreement",
    key: "projectsdashboard",
    isSwitch: true,
    isFuture: true,
    href: "/projects/dashboard",
    component: <ProductPage />,
    icon: <AttachMoney size="12px" />,
  }, */

  {
    type: "direct",
    name: "Deliver",
    key: "searchpremises-suggestions",
    isSwitch: true,
    isFuture: true,
    href: "/search/premises-suggestions",
    component: <ProductPage />,
    icon: <Carpenter size="12px" color="secondary" />,
  },/* 
  {
    type: "direct",
    name: "Evaluation",
    key: "searchpremises-suggestions",
    isSwitch: true,
    isFuture: true,
    href: "/search/premises-suggestions",
    component: <ProductPage />,
    icon: <AddBusiness size="12px" />,
  }, */
  /*  { type: "divider", key: "divider-4" },
   { type: "title", title: "Definitions", key: "informative-title" },
   {
     type: "direct",
     name: "Work Settings",
     key: "workplaceslist",
     href: "/workplaces/list",
     icon: <Office size="12px" />,
   },
   {
     type: "direct",
     name: "Activities",
     key: "activitieslist",
     href: "/activities/list",
     icon: <Shop size="12px" />,
   }, */
];

export default routes;


import { FilloutStandardEmbed } from "@fillout/react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGridPro } from "@mui/x-data-grid-pro";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logoSlack from "../../../../assets/images/small-logos/logo-slack.svg";
import team1 from "../../../../assets/images/team-1.jpg";
import team2 from "../../../../assets/images/team-2.jpg";
import team3 from "../../../../assets/images/team-3.jpg";
import team4 from "../../../../assets/images/team-4.jpg";
import team5 from "../../../../assets/images/team-5.jpg";
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import { TrForm } from "../../../../components/_dashboard/survey";
import { IntroTimeline, IntroTimelineDialog } from "../../../../components/_dashboard/workplacer";
import PlaceholderCard from "../../../../examples/Cards/PlaceholderCard";
import ComplexProjectCard from "../../../../examples/Cards/ProjectCards/ComplexProjectCard";
import Footer from "../../../../examples/Footer";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import useAuth from "../../../../hooks/useAuth";
import { getJourney, getJourneys } from "../../../../redux/slices/site";
import CsvUploader from "../../../../utils/CsvUploader3.tsx";
import Header from "../components/Header/index.js";

import company from "../../../../redux/slices/company";

function AllProjects() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { journeys, journey } = useSelector((state) => state.site);
  // ComplexProjectCard dropdown menu state
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  const [premiumSupportMenu, setPremiumSupportMenu] = useState(null);
  const [designToolsMenu, setDesignToolsMenu] = useState(null);
  const [lookingGreatMenu, setLookingGreatMenu] = useState(null);
  const [developerFirstMenu, setDeveloperFirstMenu] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [introValue, setIntroValue] = useState(1);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const [respondents, setRespondents] = useState([]);

  // TeamProfileCard dropdown menu handlers
  const openSlackBotMenu = (event) => setSlackBotMenu(event.currentTarget);
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const openPremiumSupportMenu = (event) => setPremiumSupportMenu(event.currentTarget);
  const closePremiumSupportMenu = () => setPremiumSupportMenu(null);
  const openDesignToolsMenu = (event) => setDesignToolsMenu(event.currentTarget);
  const closeDesignToolsMenu = () => setDesignToolsMenu(null);
  const openLookingGreatMenu = (event) => setLookingGreatMenu(event.currentTarget);
  const closeLookingGreatMenu = () => setLookingGreatMenu(null);
  const openDeveloperFirstMenu = (event) => setDeveloperFirstMenu(event.currentTarget);
  const closeDeveloperFirstMenu = () => setDeveloperFirstMenu(null);

  useEffect(() => {
    if (!user.id) return;
    dispatch(getJourneys());
  }, [user, dispatch]);

  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={close}>Action</MenuItem>
      <MenuItem onClick={close}>Another action</MenuItem>
      <MenuItem onClick={close}>Something else here</MenuItem>
    </Menu>
  );
  const getProject = (id) => {
    if (id) {
      dispatch(getJourney(id));
    };
  }

  const handleSelect = (newValue) => {
    setTabValue(newValue);
  }
  const handleStage = (newValue) => {
    setIntroValue(newValue);
  }

  const handleUpload = (d) => {
    setData(d);
    console.log(d);
  };

  async function uploadOutput(output) {
    const url = "${process.env.REACT_APP_BACKEND_URL}/respondents/create";

    for (let i = 1; i < output.length; i += 1) {
      console.log("i", i);
      const body = {
        journey_id: journey.id,
        company_id: user.companyHubspot,
        zip: output[i].zip,
        city: output[i].city,
        email: output[i].email,
        first_name: output[i].firstName,
        last_name: output[i].lastName,
        survey_id: 1,
        department: output[i].department,
      };
      const response = await axios.post(url, body).then((response) => {
        console.log("response", response);
      });
    }
  }

  const handleOk = () => {
    let responds = [];
    for (let i = 1; i < data.length; i += 1) {
      const id = data[i][0];
      const email = data[i][1];
      const firstName = data[i][2];
      const lastName = data[i][3];
      const zip = data[i][4];
      const city = data[i][5];
      const department = data[i][6];
      const obj = {
        id,
        email,
        firstName,
        lastName,
        zip,
        city,
        department,
      };
      responds.push(obj);
    }
    setRespondents(responds);
    uploadOutput(responds);
  };

  let idCounter = 0;

  const createRow = (ro) => {
    idCounter += 1;
    return {
      id: idCounter,
      ...ro,
      company_id: company.id,
      survey_id: 1,
    };
  };

  const handleAddRow = (ro) => {
    setRows((prevRows) => [...prevRows, createRow(ro)]);
  };
  const handleDeleteRow = (idx) => {
    if (rows.length === 0) {
      return;
    }
    setRows((prevRows) => {
      const rowToDeleteIndex = idx;
      return [
        ...rows.slice(0, rowToDeleteIndex),
        ...rows.slice(rowToDeleteIndex + 1),
      ];
    });
  };
  const columnsResp = [
    { field: "id", headerName: "Id" },
    { field: "email", type: "string", headerName: "Email" },
    { field: "firstName", type: "string", headerName: "First name" },
    { field: "lastName", type: "string", headerName: "Last name" },
    { field: "zip", type: "string", headerName: "Zip" },
    { field: "city", type: "string", headerName: "City" },
    { field: "department", type: "string", headerName: "City" },
  ];
  return (
    <DashboardLayout>

      <Dialog open={open} fullScreen>
        <DialogContent>
          <Grid container sx={{ width: '100%' }}>
            <Grid item xs={12} md={4}>
              <IntroTimelineDialog onStage={handleStage} selectedValue={introValue} />
            </Grid>
            <Grid item xs={12} md={8}>

              {introValue === 1 && (
                <FilloutStandardEmbed
                  filloutId="hy1YdhaK8Cus"
                  inheritParameters
                  height="100%"
                  width='80vw'
                  sx={{ height: '100%', width: '100%' }}
                  parameters={{
                    company_id: user.companyHubspot,
                    survey_id: 1,
                    company_name: "Workplacer",
                  }}
                />
              )}

              {introValue === 2 && (
                <Box sx={{ width: '100%', height: '100%' }}>
                  <TrForm tenant={journey?.company} />
                </Box>
              )}
              {introValue === 3 && (
                <>

                  <DialogContentText>
                    {"Upload respondent list."}
                  </DialogContentText>
                  <DialogContentText variant="caption">
                    {"Make sure to use template formatting."}
                  </DialogContentText>
                  <CsvUploader onUpload={handleUpload} />
                  <Box sx={{ width: "100%", mt: 3 }}>
                    <Typography>Add respondents individually</Typography>
                    <Stack direction="row" spacing={1}>
                      <Button size="small" onClick={handleDeleteRow}>
                        Delete a row
                      </Button>
                      <Button size="small" onClick={handleAddRow}>
                        Add a row
                      </Button>
                    </Stack>
                    <Box sx={{ height: 400, width: "50vw", mt: 1 }}>
                      <DataGridPro rows={rows} columns={columnsResp} />
                    </Box>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} >Cancel</Button>
          <Button >Add</Button>
        </DialogActions>
      </Dialog>
      <Header onSelect={handleSelect} />
      <SoftBox pt={5} pb={2}>
        <Grid container>
          <Grid item xs={12} md={8}>
            {tabValue === 0 && (
              <SoftBox mb={1} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <SoftTypography variant="h5">Guide</SoftTypography>
                <Button onClick={() => setOpen(true)}> Open Guide</Button>
                <IntroTimeline />
              </SoftBox>

            )}
            {tabValue === 1 && (
              <>
                <SoftBox mb={1}>
                  <SoftTypography variant="h5">Your Workplace Journeys</SoftTypography>
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="body2" color="text">
                    Select a journey to start working.
                  </SoftTypography>
                </SoftBox>
                <SoftBox mt={{ xs: 1, lg: 3 }} mb={1}>
                  <Grid container spacing={3}>
                    {journeys.map((j) => (
                      <>

                        <Grid item xs={12} md={6} lg={4} key={j.id}>
                          <ComplexProjectCard
                            image={logoSlack}
                            title={j.label}
                            key={j.id}
                            id={j.id}
                            description={j.company_id}
                            dateTime="02.03.22"
                            members={[team1, team2, team3, team4, team5]}
                            onSelect={getProject}
                            selected={j.id === journey.id ? true : false}
                          />
                        </Grid>
                      </>
                    ))}
                    <Grid item xs={12} md={6} lg={4}>
                      <Link to="/projects/new">
                        <PlaceholderCard title={{ variant: "h5", text: "New project" }} />
                      </Link>
                    </Grid>
                  </Grid>
                </SoftBox>
              </>
            )}

          </Grid>
        </Grid>

      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AllProjects;

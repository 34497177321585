import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { SnackbarProvider } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import 'react-internet-meter/dist/index.css';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import brandwidth from "./assets/images/logowidth.png";
import brand from "./assets/images/lohow.png";
import theme from "./assets/theme";
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import ScrollToTop from './components/ScrollToTop';
import { geoConfig, gridConfig } from './config';
import { CableContext, CableProvider } from './contexts/cable';
import Sidenav from "./examples/Sidenav";
import useAuth from './hooks/useAuth';
import NoInternetConnection from './NoInternet';
import { getAllCompanies, getCompanies, getCompany, getDepartments } from "./redux/slices/company";
import { getCompanyContracts, getCompanyJourneys, getJourney, getProspect, updateContract } from "./redux/slices/site";
import { getAllDeepDives, getAllMidpoints } from "./redux/slices/survey";
import { getActivityprofiles, getGeneralAreas, getGoaldomains, getJourneySpacebricks, getMeetings, getMobilityprofiles, getOfficefeatures, getWorkplacedistributions, getWorkplaces } from "./redux/slices/workplace";
import Router from './routes';
import routez from './routez';
import { setMiniSidenav, useSoftUIController } from "./softcontext";
import GlobalStyles from './theme/globalStyles';
import TestError from './components/TestError';
import ErrorBoundary from "./components/ErrorBoundary";
import JourneyWrapper from './components/JourneyWrapper';
import { Fab, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import SupportIcon from '@mui/icons-material/Support';
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Stack } from "@mui/material";
import Slider from '@mui/material/Slider';
// ----------------------------------------------------------------------

Geocode.setApiKey(geoConfig);
LicenseInfo.setLicenseKey(gridConfig);

// ----------------------------------------------------------------------

function App() {
  const { isInitialized } = useAuth();
  const { user, login } = useAuth();
  const dispatchreact = useDispatch();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, layout, openConfigurator, sidenavColor } = controller;
  const { journey, contract, contracts, prospect, journeys } = useSelector((state) => state.site);
  const { workplaces, activityprofiles, spacebricks, workplacedistributions, mobilityprofiles, meetings, officefeatures, goaldomains, generalareas } = useSelector((state) => state.workplace);
  const { company, demouse, companies } = useSelector((state) => state.company);
  const { allMidpoints, allDeepDives } = useSelector((state) => state.survey);

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const [readyToLoad, setReadyToLoad] = useState(false);
  const location = useLocation();

  const hiddenSidenavRoutes = [
    '/login',
    '/signup',
    '/forgot-password',
    '/deepdive/:id',
    '/midpoint/:id',
    '/deepdive/:journey_id/:id',
    '/midpoint/:journey_id/:id',
    '/deepdive',
    '/midpoint',
  ];

  const shouldShowSidenav = !hiddenSidenavRoutes.some(route => {
    const routePattern = route
      .replace(/:[^\s/]+/g, '[^\\s/]+')
      .replace(/\//g, '\\/');

    const regex = new RegExp(`^${routePattern}$`);
    return regex.test(location.pathname);
  });

  useEffect(() => {
    if (!workplaces.length) {
      dispatchreact(getWorkplaces());
    }
  }, [dispatchreact, workplaces]);

  useEffect(() => {
    if (!generalareas.length) {
      dispatchreact(getGeneralAreas());
    }
  }, [dispatchreact, generalareas]);

  useEffect(() => {
    if (!goaldomains.length) {
      dispatchreact(getGoaldomains());
    }
  }, [dispatchreact, goaldomains]);

  useEffect(() => {
    if (!companies?.length) {
      dispatchreact(getAllCompanies());
    }
  }, [dispatchreact, companies]);
  useEffect(() => {
    if (!activityprofiles.length) {
      dispatchreact(getActivityprofiles());
    }
  }, [dispatchreact, activityprofiles]);
  /* 
    useEffect(() => {
      if (!allMidpoints.length) {
        dispatchreact(getAllMidpoints());
      }
    }, [dispatchreact, allMidpoints]); */

  useEffect(() => {
    if (!allDeepDives.length) {
      dispatchreact(getAllDeepDives());
    }
  }, [dispatchreact, allDeepDives]);
  useEffect(() => {
    if (!journeys.length && user?.companyHubspot) {
      console.log('getting journeys');
      dispatchreact(getCompanyJourneys(user.companyHubspot));
    }
  }, [dispatchreact, journeys]);

  useEffect(() => {
    if (!workplacedistributions.length) {
      dispatchreact(getWorkplacedistributions());
    }
  }, [dispatchreact, workplacedistributions]);

  useEffect(() => {
    if (!meetings.length) {
      dispatchreact(getMeetings());
    }
  }, [dispatchreact, meetings]);

  useEffect(() => {
    if (!officefeatures.length) {
      dispatchreact(getOfficefeatures());
    }
  }, [dispatchreact, officefeatures]);

  useEffect(() => {
    if (!mobilityprofiles.length) {
      dispatchreact(getMobilityprofiles());
    }
  }, [dispatchreact, mobilityprofiles]);

  useEffect(() => {
    if (user.id && user?.companyHubspot && !company) {
      console.log('user found, getting journeys, sites and company data');
      dispatchreact(getCompany(user.companyHubspot));/* 
      dispatchreact(getDepartments(user.companyHubspot)); */
    }
  }, [user, dispatchreact]);

  useEffect(() => {

    if (demouse) {
      dispatchreact(getCompany(15578996295))
    }
  }, [demouse, dispatchreact]);

  useEffect(() => {
    if (journey) {

    }
  }, [journey]);

  useEffect(() => {
    if (company) {
      dispatchreact(getCompanyContracts(company));
      dispatchreact(getDepartments(company.id));
    }
  }, [company, dispatchreact]);

  useEffect(() => {
    if (!contracts.length) return;
    console.log('contracts', contracts);
    contracts.filter((c) => c.city && c.address && !c.latitude && !c.longitude).forEach(contract => {
      Geocode.fromAddress(`${contract.address}, ${contract.city}`).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          dispatchreact(updateContract(contract.id, { latitude: lat, longitude: lng }));
        },
        (error, response) => {
          console.log('error', contract, response);
          console.error(error);
        }
      );
    });
  }, [contracts]);


  useEffect(() => {
    if (contract?.journeys?.length) {
      dispatchreact(getJourney(contract.journeys[0].id));
    }
  }, [contract, dispatchreact]);

  /* useEffect(() => {
    if (mgmtSubmissions || !user.companyHubspot || !journey || !workplaces || !workplaces.length) return;
    setReadyToLoad(true);
  }, [dispatchreact, user, journey, mgmtSubmissions, workplaces]);
 */

  useEffect(() => {
    if (prospect || !user.companyHubspot || !contract.prospects?.length || !workplaces || !workplacedistributions.length || !activityprofiles.length) return;
    // setReadyToLoad(true);
    console.log('get initial prospect', contract.prospects[0].id);
    dispatchreact(getProspect(contract.prospects[0].id, workplaces, activityprofiles, workplacedistributions));
  }, [dispatchreact, user, contract.prospects, prospect, workplaces, workplacedistributions, activityprofiles]);
  /* 
    useEffect(() => {
      if (!readyToLoad || mgmtSubmissions) return;
      dispatchreact(getJourneyMgmtSubmissions(user.companyHubspot, journey.id, workplaces, journey.submission_id));
    }, [dispatchreact, readyToLoad, mgmtSubmissions]); */

  // Add this state to track company changes
  const [companyChangeTimestamp, setCompanyChangeTimestamp] = useState(null);

  // Add this effect to handle company changes and force data refresh


  // Pass this function down to DashboardNavbar
  const handleCompanyChange = () => {
    setCompanyChangeTimestamp(Date.now());
  };

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const cableContext = useContext(CableContext);

  // console.log('channel', cableContext);
  useEffect(() => {
    // Add null check for user object
    if (!user || !user.id || !cableContext?.cable) return;

    const channel = cableContext.cable.subscriptions.create(
      {
        channel: "SurveyChannel",
        user_id: user.id
      },
      {
        connected: () => {
          console.log('connected');
        },
        received: (data) => {
          console.log('data received', data);
          if (data === 'reload' && user.companyHubspot) {
            dispatchreact(getCompanyJourneys(user.companyHubspot));
          }
        }
      });

    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, [user, cableContext, dispatchreact]);

  // Add new state for first-time user
  const [showFirstJourney, setShowFirstJourney] = useState(false);

  // Add new effect to check for first-time users
  useEffect(() => {
    if (user?.id && journeys?.length === 0) {
      setShowFirstJourney(true);
    }
  }, [user, journeys]);

  // Add these state variables with the existing ones
  const [openAdvisoryDialog, setOpenAdvisoryDialog] = useState(false);
  const [advisoryMessage, setAdvisoryMessage] = useState('');
  const [requestType, setRequestType] = useState('custom');

  // Add these handlers before the return statement
  const handleAdvisoryOpen = () => {
    setOpenAdvisoryDialog(true);
  };

  const handleAdvisoryClose = () => {
    setOpenAdvisoryDialog(false);
    setAdvisoryMessage('');
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const advisoryPackages = [
    {
      id: 'payg',
      title: 'Pay As You Go',
      description: 'Purchase adviosy hours as you need them',
      isCustom: true
    },
    {
      id: 'starter',
      title: 'Survey Insights Workshop',
      hours: 5,
      price: '€2.500',
      description: 'Basic advisory support for getting started'
    },
    {
      id: 'professional',
      title: 'Goal Setting & Work Model Workshop',
      hours: 10,
      price: '€4.500',
      description: 'Comprehensive support for medium-sized projects'
    },
    {
      id: 'enterprise',
      title: 'Work settings & Support areas',
      hours: 20,
      price: '€8.000',
      description: 'Full-scale advisory support for complex implementations'
    }
  ];

  const handleAdvisorySubmit = () => {
    const requestData = {
      type: requestType,
      message: requestType === 'custom' ? advisoryMessage : null,
      package: requestType !== 'custom' && requestType !== 'payg'
        ? advisoryPackages.find(pkg => pkg.id === requestType)
        : null,
      customHours: requestType === 'payg' ? customHours : null,
      totalAmount: requestType === 'payg' ? customHours * HOURLY_RATE : null
    };

    console.log('Advisory request:', requestData);
    handleAdvisoryClose();
    setRequestType('custom');
    setCustomHours(5); // Reset hours
  };

  // Add new state variables
  const [customHours, setCustomHours] = useState(5);
  const HOURLY_RATE = 500; // €500 per hour

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <CableProvider>
          <>
            {/*  <TestError /> */}

            <CssBaseline />
            <GlobalStyles />
            <ProgressBarStyle />
            <ScrollToTop />
            <SnackbarProvider />
            <NoInternetConnection>

              {isInitialized ? (
                <>
                  {(user.id || demouse) && shouldShowSidenav && (
                    <>
                      <Sidenav
                        color={sidenavColor}
                        brand={brand}
                        brandwidth={brandwidth}
                        brandName="Workplacer.ai"
                        routes={routez}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                      />
                    </>
                  )}
                  <JourneyWrapper>
                    <Router
                      onCompanyChange={handleCompanyChange}
                      showFirstJourney={showFirstJourney}
                    />
                  </JourneyWrapper>
                  {(user.id || demouse) && (
                    <>
                      <Fab
                        color="primary"
                        variant="extended"
                        sx={{
                          position: 'fixed',
                          bottom: 24,
                          right: 24,
                          zIndex: 1000
                        }}
                        onClick={handleAdvisoryOpen}
                      >
                        <SupportIcon sx={{ mr: 1 }} />
                        Request Advisory
                      </Fab>

                      <Dialog
                        open={openAdvisoryDialog}
                        onClose={handleAdvisoryClose}
                        maxWidth="md"
                        fullWidth
                      >
                        <DialogTitle>
                          <SoftTypography variant="h4" fontWeight="medium">
                            Request Advisory Support
                          </SoftTypography>
                        </DialogTitle>
                        <DialogContent>
                          <SoftBox p={2}>
                            <SoftTypography variant="h6" fontWeight="regular" mb={2}>
                              Choose a package or make a custom request
                            </SoftTypography>

                            <Stack spacing={2} mb={3}>
                              {advisoryPackages.map((pkg) => (
                                <SoftBox
                                  key={pkg.id}
                                  p={2}
                                  border={1}
                                  borderRadius="lg"
                                  borderColor={requestType === pkg.id ? 'primary.main' : 'grey.300'}
                                  sx={{
                                    cursor: 'pointer',
                                    '&:hover': { borderColor: 'primary.main' },
                                    transition: 'all 0.2s'
                                  }}
                                  onClick={() => setRequestType(pkg.id)}
                                >
                                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Stack spacing={1}>
                                      <SoftTypography variant="h6" fontWeight="medium">
                                        {pkg.title}
                                      </SoftTypography>
                                      <SoftTypography variant="body2" color="text">
                                        {pkg.description}
                                      </SoftTypography>
                                    </Stack>
                                    {!pkg.isCustom && (
                                      <Stack alignItems="flex-end">
                                        <SoftTypography variant="h6" color="primary">
                                          {pkg.price}
                                        </SoftTypography>
                                        <SoftTypography variant="body2" color="text">
                                          {pkg.hours} hours
                                        </SoftTypography>
                                      </Stack>
                                    )}
                                  </Stack>

                                  {pkg.isCustom && requestType === 'payg' && (
                                    <SoftBox mt={3}>
                                      <Stack spacing={2}>
                                        <Stack direction="row" justifyContent="space-between">
                                          <SoftTypography variant="body2" color="text">
                                            Select Hours
                                          </SoftTypography>
                                          <SoftTypography variant="h6" color="primary">
                                            {formatCurrency(customHours * HOURLY_RATE)}
                                          </SoftTypography>
                                        </Stack>
                                        <Slider
                                          value={customHours}
                                          onChange={(e, newValue) => setCustomHours(newValue)}
                                          min={2}
                                          max={40}
                                          step={1}
                                          marks={[
                                            { value: 2, label: '2h' },
                                            { value: 20, label: '20h' },
                                            { value: 40, label: '40h' },
                                          ]}
                                          valueLabelDisplay="auto"
                                          valueLabelFormat={(value) => `${value}h`}
                                        />
                                        <Stack direction="row" justifyContent="space-between">
                                          <SoftTypography variant="caption" color="text">
                                            Rate: {formatCurrency(HOURLY_RATE)}/hour
                                          </SoftTypography>
                                          <SoftTypography variant="caption" color="text">
                                            Total: {customHours} hours
                                          </SoftTypography>
                                        </Stack>
                                      </Stack>
                                    </SoftBox>
                                  )}
                                </SoftBox>
                              ))}

                              <SoftBox
                                p={2}
                                border={1}
                                borderRadius="lg"
                                borderColor={requestType === 'custom' ? 'primary.main' : 'grey.300'}
                                sx={{
                                  cursor: 'pointer',
                                  '&:hover': { borderColor: 'primary.main' },
                                  transition: 'all 0.2s'
                                }}
                                onClick={() => setRequestType('custom')}
                              >
                                <SoftTypography variant="h6" fontWeight="medium" mb={2}>
                                  Custom Request
                                </SoftTypography>
                                {requestType === 'custom' && (
                                  <TextField
                                    autoFocus
                                    margin="dense"
                                    label="How can we help you?"
                                    type="text"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={advisoryMessage}
                                    onChange={(e) => setAdvisoryMessage(e.target.value)}
                                  />
                                )}
                              </SoftBox>
                            </Stack>
                          </SoftBox>
                        </DialogContent>
                        <DialogActions sx={{ p: 3 }}>
                          <SoftButton onClick={handleAdvisoryClose} variant="text" color="dark">
                            Cancel
                          </SoftButton>
                          <SoftButton
                            onClick={handleAdvisorySubmit}
                            variant="gradient"
                            color="primary"
                            disabled={requestType === 'custom' && !advisoryMessage.trim()}
                          >
                            Submit Request
                          </SoftButton>
                        </DialogActions>
                      </Dialog>
                    </>
                  )}
                </>) : <LoadingScreen />}

            </NoInternetConnection>
          </>
        </CableProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;

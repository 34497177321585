import React from 'react';
import { useLocation } from 'react-router-dom';
import FirstJourney from '../pages/dashboard/intro/FirstJourney';
import { useSelector } from 'react-redux';

const JourneyWrapper = ({ children }) => {
    const location = useLocation();
    const { journeys, contracts } = useSelector(state => state.site);
    const { company } = useSelector(state => state.company);
    const isDemoPath = location.pathname.startsWith('/demo');
    console.log('j wrapper check', contracts, journeys, company);

    if (company && !company?.journeys?.length && !isDemoPath) {
        return <FirstJourney />;
    }

    return children;
};

export default JourneyWrapper; 
import React, { useState } from 'react';
import { Box, Paper, Typography, Grid } from '@mui/material';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import RGL, { WidthProvider } from 'react-grid-layout';
import DraggableComponent from './DraggableComponent';
import ComponentPalette from './ComponentPalette';
import { COMPONENT_TEMPLATES } from './constants';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ReactGridLayout = WidthProvider(RGL);

const PDFDesignArea = () => {
    const ASPECT_RATIO = 16/9;
    const MAX_WIDTH = 1000;
    const WIDTH = Math.min(MAX_WIDTH, window.innerWidth * 0.7);
    const HEIGHT = WIDTH / ASPECT_RATIO;
    
    const [components, setComponents] = useState([]);
    const [layout, setLayout] = useState([]);

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination } = result;

        if (source.droppableId === 'palette' && destination.droppableId === 'slide') {
            const newComponent = COMPONENT_TEMPLATES[result.draggableId];
            const componentId = `${newComponent.type}-${Date.now()}`;
            
            // Add new component with default grid properties
            const newLayout = {
                i: componentId,
                x: 0,
                y: 0,
                w: 6,
                h: 4,
                minW: 3,
                minH: 2
            };

            setComponents(prev => [...prev, { ...newComponent, id: componentId }]);
            setLayout(prev => [...prev, newLayout]);
        }
    };

    const onLayoutChange = (newLayout) => {
        setLayout(newLayout);
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Box sx={{ p: 3, backgroundColor: '#f0f0f0', minHeight: '100vh' }}>
                <Typography variant="h5" gutterBottom>
                    Presentation Design Area
                </Typography>
                
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: 2
                        }}>
                            <Paper 
                                elevation={3}
                                sx={{
                                    width: WIDTH,
                                    height: HEIGHT,
                                    backgroundColor: 'white',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                                }}
                            >
                                <Droppable droppableId="slide">
                                    {(provided) => (
                                        <Box
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            sx={{
                                                position: 'absolute',
                                                top: '5%',
                                                bottom: '5%',
                                                left: '8%',
                                                right: '8%',
                                                border: '1px dashed #ccc',
                                                borderRadius: '4px',
                                            }}
                                        >
                                            <ReactGridLayout
                                                className="layout"
                                                layout={layout}
                                                cols={12}
                                                rowHeight={30}
                                                width={WIDTH * 0.84}
                                                onLayoutChange={onLayoutChange}
                                                compactType={null}
                                                preventCollision={true}
                                                isResizable={true}
                                                isDraggable={true}
                                            >
                                                {components.map((component) => (
                                                    <div key={component.id}>
                                                        <DraggableComponent
                                                            component={component}
                                                        />
                                                    </div>
                                                ))}
                                            </ReactGridLayout>
                                            {provided.placeholder}
                                        </Box>
                                    )}
                                </Droppable>
                            </Paper>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <ComponentPalette height={HEIGHT} />
                    </Grid>
                </Grid>
            </Box>
        </DragDropContext>
    );
};

export default PDFDesignArea; 
import { createSlice } from '@reduxjs/toolkit';
import { map } from 'lodash';

import axios from 'axios';
import { getProspect } from './site';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingResponses: false,
  isLoadingPremises: false,
  joyrneysReceived: false,
  error: false,
  dealsOne: [],
  dealsTwo: [],
  deals: [],
  premises: [],
  destinations: [],
  journey: null,
  space: null,
  hasNospace: false,
  form: '',
  answers: [],
  responses: [],
  vkpipeline: [],
  journeys: [],
  vkdeals: [],
  vkdeal: [],
  pipeline: [],
  details: [],
  createdlm: [],
  createdlw: [],
  engagements: [],
  wonlm: [],
  wonlw: [],
  goals: [],
  companygoals: [],
  deal: null
};

const slice = createSlice({
  name: 'deal',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startLoadingResponses(state) {
      state.isLoadingResponses = true;
    },
    startLoadingPremises(state) {
      state.isLoadingPremises = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getVkDealsSuccess(state, action) {
      state.isLoading = false;
      state.vkdeals = action.payload;
    },
    getJourneysSuccess(state, action) {
      state.isLoading = false;
      state.journeysReceived = true;
      state.journeys = action.payload;
      
      if (!state.journey) {
        // TODO: UPDATE TO BE UNINAMIOUS
        state.journey = action.payload[0];
      }
    },
    getJourneySuccess(state, action) {
      console.log('journey', action.payload);
      state.isLoading = false;
      state.journey = action.payload;
    },

    onSetJourney(state, action) {
      const journeyNew = action.payload;
      state.journey = journeyNew;
    },

    getJourneySpaceSuccess(state, action) {
      state.isLoading = false;
      console.log('action.payload', action.payload);
      const { spaces } = action.payload;
      if(spaces.length > 0) {
      state.space = action.payload.spaces[0]
      } else {
        state.hasNoSpace = true;
      };
    },

    getGoalsSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.goals = action.payload;
    },

    getCompanyGoalsSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.companygoals = action.payload;
    },
    createSpaceSuccess(state, action) {
      state.isLoading = false;
      console.log('action.payload', action.payload);
      state.space = action.payload
      state.hasNospace = false;
    },
    getPremisesSuccess(state, action) {
      state.isLoadingPremises = false;
      state.premises = action.payload;
    },

    setFloorsSuccess(state, action) {
      state.premises = action.payload;
      state.isLoadingPremises = false;
    },
    setPremisesSuccess(state, action) {
      state.premises = action.payload;
      state.isLoadingPremises = false;
    },
    getFormSuccess(state, action) {
      state.isLoading = false;
      state.form = action.payload;
    },
    getAnswersSuccess(state, action) {
      state.isLoading = false;
      state.answers = action.payload;
    },
    getDestinationsSuccess(state, action) {
      state.isLoading = false;
      state.destinations = action.payload;
    },
    getVkDealSuccess(state, action) {
      state.isLoading = false;
      state.vkdeal = action.payload;
    },
    getDealsOSuccess(state, action) {
      state.isLoading = false;
      const { one, two } = action.payload;
      state.sitesTwo = two;
      state.sitesOne = one;
    },
    getDealsSuccess(state, action) {
      state.isLoading = false;
      state.sites = action.payload;
    },
    getResponsesSuccess(state, action) {
      state.isLoadingResponses = false;
      state.responses = action.payload;
    },

    // GET POST
    getDealSuccess(state, action) {
      state.isLoading = false;
      state.site = action.payload;
    },
    // GET POST
    getVkPipelineSuccess(state, action) {
      state.isLoading = false;
      const pipe = action.payload[0];
      const repipe = pipe.dealstages.map((p) => {
        const r = { ...p, cardIds: p.deals?.map((d) => `${d.id}`) };
        return r;
      });
      state.vkpipeline = repipe.sort((a, b) => (b.displayOrder < a.displayOrder ? 1 : -1));
    },
    // GET POST
    getPipelineSuccess(state, action) {
      const yr = new Date().getFullYear()
      state.isLoading = false;
      const pipe = action.payload[0];
      const repipe = pipe.dealstages.map((p) => {
        const r = { ...p, cardIds: p.deals?.filter((d) => d.goal === `${yr}`).map((d) => `${d.id}`) };
        return r;
      });
      state.pipeline = repipe.sort((a, b) => (b.displayOrder < a.displayOrder ? 1 : -1));
    },

    addDeal(state, action) {
      const dealCurrent = action.payload;
      const newDeals = state.sitesTwo.push(dealCurrent);
      state.sitesTwo = newDeals;
    },

    updateDeals(state, action) {
      const { dealId, dealstage } = action.payload;
      const updateDeals = map(state.sitesTwo, (deal) => {
        if (deal.id === dealId) {
          return {
            ...deal,
            dealstage_id: dealstage.id
          }
        };
        return deal;
      });

      state.vkdeals = updateDeals;
    }
  }
});

// Reducer
export default slice.reducer;

export const { addDeal, updateDeals, onSetJourney } = slice.actions;
// ----------------------------------------------------------------------

export function getAllDeals() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/deals/index`);
      dispatch(slice.actions.getDealsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getResponses(company_id) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/responses/index`, {
        params: { company_id }
      });
      console.log('response', response.data);
      dispatch(slice.actions.getResponsesSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getDeals() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/deals/mindindex`);
      dispatch(slice.actions.getDealsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJourneys() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/journeys/index/`);
      dispatch(slice.actions.getJourneysSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getCompanyJourneys(company_id) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/journeys/companyjourneys/${company_id}`);
      console.log('journeys', response.data);
      dispatch(slice.actions.getJourneysSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateJourney(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('uodate journey', params);
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/journeys/update/${id}`, params);
      dispatch(getJourney(response.data.id));
      console.log('journey updated', response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateDriver(id, params, workplaces, activityprofiles, workplacedistributions) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('uodate goal', params);
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/drivers/update/${id}`, params);
      dispatch(getProspect(response.data.prospect_id), workplaces, activityprofiles, workplacedistributions);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function updateGoal(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('uodate goal', params);
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/goals/update/${id}`, params);
      dispatch(getJourney(response.data.journey_id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function getJourneyGoals(journey_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('getting journey ggoals');
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/goals/journeyindex`, {
        params: { journey_id }
      });
      console.log('journey goals fetched', response.data);
      dispatch(slice.actions.getGoalsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getProspectGoals(prospect_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('getting journey ggoals');
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/goals/prospectindex`, {
        params: { prospect_id }
      });
      console.log('prospect goals fetched', response.data);
      dispatch(slice.actions.getGoalsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
export function getCompanyGoals(company_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('getting company ggoals');
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/goals/companyindex/`, {
        params: { company_id }
      });
      dispatch(slice.actions.getCompanyGoalsSuccess(response.data));
      console.log('company goals fetched', response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function updateKpi(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('uodate kpi', params);
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/kpis/update/${id}`, params);
      dispatch(getJourney(response.data.journey_id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJourneySpace(id) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/journeys/journeyspace/${id}`);
      dispatch(slice.actions.getJourneySpaceSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAnswers(id, fields) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/journeys/answers/${id}`,
        { params: { fields: fields } });
      dispatch(slice.actions.getAnswersSuccess(response.data.items));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getForm(id) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/journeys/form/${id}`);
      dispatch(slice.actions.getFormSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getPremises(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingPremises());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/premises/index/`);
      dispatch(slice.actions.getPremisesSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setPremises(journey, format, sqm) {
  return async (dispatch) => {
    try {

      const properties = { sqm, journey_id: journey?.id, format };
      const url = `${process.env.REACT_APP_BACKEND_URL}/premises/create`;
      const response = await axios.post(url, {
        premise: properties
      });
      console.log('response prmeise', response.data);
      //dispatch(slice.actions.startLoadingLoc());
      dispatch(slice.actions.setPremisesSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }

  };
}

// ----------------------------------------------------------------------

export function removeFloors(id) {
  return async (dispatch) => {
    try {

      const url = `${process.env.REACT_APP_BACKEND_URL}/floors/delete/${id}`;
      const response = await axios.delete(url, {
        id
      });
      console.log('response floor', response.data);
      //dispatch(slice.actions.startLoadingLoc());
      dispatch(slice.actions.setFloorsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }

  };
}

// ----------------------------------------------------------------------

export function removePremise(id) {
  return async (dispatch) => {
    try {

      const url = `${process.env.REACT_APP_BACKEND_URL}/premises/delete/${id}`;
      const response = await axios.delete(url, {
        id
      });
      console.log('response floor', response.data);
      //dispatch(slice.actions.startLoadingLoc());
      dispatch(slice.actions.setPremisesSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }

  };
}
// ----------------------------------------------------------------------

export function setFloors(journey, premise, sqm) {
  return async (dispatch) => {
    try {

      const properties = { sqm, journey_id: journey?.id, premise_id: premise };
      const url = `${process.env.REACT_APP_BACKEND_URL}/floors/create`;
      const response = await axios.post(url, {
        floor: properties
      });
      console.log('response floor', response.data);
      //dispatch(slice.actions.startLoadingLoc());
      dispatch(slice.actions.setFloorsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }

  };
}
// ----------------------------------------------------------------------

export function getVkDeals() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/deals/vkindex`);
      dispatch(slice.actions.getVkDealsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDestinations() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/destinations/index`);
      dispatch(slice.actions.getDestinationsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getJourney(id) {
  return async (dispatch) => {
    console.log('get journey', id);
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/journeys/journey/${id}`, {
        params: { id }
      });
      dispatch(slice.actions.getJourneySuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function createSpace({journey_id, company_id}) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/spaces/create`, {
        company_id, journey_id,
      });
      dispatch(slice.actions.createSpaceSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getVkDeal(id) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/deals/vkdeal/${id}`, {
        params: { id }
      });
      dispatch(slice.actions.getVkDealSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function getDeal(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(id);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/deals/deal/${id}`, {
        params: { id }
      });
      dispatch(slice.actions.getDealSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPipeline() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/deals/mindpipeline`);
      dispatch(slice.actions.getPipelineSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVkPipeline() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/deals/vkpipeline`);
      dispatch(slice.actions.getVkPipelineSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
import {
	Avatar, Box,
	Card,
	Container, Grid, IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText, Stack, Step,
	StepLabel, Stepper, Chip,
	CardContent,
	Divider,
	InputAdornment,
	Select,
	MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SoftInput from "../../../components/SoftInput";
import { createJourney, getCompanyContracts } from '../../../redux/slices/site';

// You might want to import your own icons/images
import { DeleteOutline, Timeline, Info, Groups, AspectRatio, Chair, SupportAgent, Domain, Business, WorkOutline, LocationOn, AccountBalance, CompareArrows, Dashboard, EjectOutlined, Settings } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useSelector } from 'react-redux';
import JourneyIllustration from '../../../assets/images/firstproject.svg';
import SoftBox from '../../../components/SoftBox';
import SoftButton from '../../../components/SoftButton';
import SoftTypography from '../../../components/SoftTypography';
import { mapConfig } from '../../../config';
import Departments from '../../../examples/Lists/Departments';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import { getCompany, updateCompany } from '../../../redux/slices/company';
import AddIcon from '@mui/icons-material/Add';
import { alpha } from '@mui/material/styles';
import getSymbolFromCurrency from 'currency-symbol-map';

const RootStyle = styled('div')(({ theme }) => ({
	minHeight: '100%',
	paddingTop: theme.spacing(5),
	paddingBottom: theme.spacing(5),
}));

const StyledCard = styled(Card)(({ theme, selected }) => ({
	cursor: 'pointer',
	transition: 'all 0.2s ease-in-out',
	border: `2px solid ${selected ? theme.palette.primary.main : 'transparent'}`,
	backgroundColor: selected ? alpha(theme.palette.primary.main, 0.1) : theme.palette.background.paper,
	'&:hover': {
		transform: 'translateY(-5px)',
		boxShadow: theme.shadows[4],
	},
	'& .MuiCardContent-root': {
		paddingBottom: `${theme.spacing(5)} !important`,
	}
}));

const FeatureChip = styled(Chip)(({ theme }) => ({
	margin: '4px',
	backgroundColor: alpha(theme.palette.primary.main, 0.1),
	'& .MuiChip-icon': {
		color: theme.palette.primary.main,
	}
}));

const PriceTag = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: 8,
	right: 8,
	padding: '2px 8px',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.primary.main, 0.1),
	color: theme.palette.primary.main,
	fontWeight: 'bold',
	fontSize: '0.875rem'
}));

const PriceSection = styled(Box)(({ theme }) => ({
	position: 'sticky',
	bottom: 0,
	padding: theme.spacing(0),
	borderRadius: 3,
	backdropFilter: 'blur(8px)',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: theme.spacing(0.5),
	width: '100%',
	zIndex: 1
}));

const PriceCalculation = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spacing(0.5),
	padding: theme.spacing(0),
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.background.paper, 0.6),
	width: '100%'
}));

const OriginalPrice = styled(SoftTypography)(({ theme }) => ({
	textDecoration: 'line-through',
	color: theme.palette.text.secondary,
	fontSize: '0.875rem'
}));

const currencies = [
	{ code: 'EUR', label: 'Euro' },
	{ code: 'USD', label: 'US Dollar' },
	{ code: 'GBP', label: 'British Pound' },
	{ code: 'SEK', label: 'Swedish Krona' },
	{ code: 'NOK', label: 'Norwegian Krone' },
	{ code: 'DKK', label: 'Danish Krone' },
	{ code: 'CHF', label: 'Swiss Franc' },
	{ code: 'AUD', label: 'Australian Dollar' },
	{ code: 'CAD', label: 'Canadian Dollar' },
	{ code: 'JPY', label: 'Japanese Yen' },
	{ code: 'CNY', label: 'Chinese Yuan' },
	{ code: 'HKD', label: 'Hong Kong Dollar' },
	{ code: 'SGD', label: 'Singapore Dollar' },
	{ code: 'INR', label: 'Indian Rupee' },
	{ code: 'NZD', label: 'New Zealand Dollar' },
	{ code: 'PLN', label: 'Polish Złoty' },
	{ code: 'BRL', label: 'Brazilian Real' },
	{ code: 'ZAR', label: 'South African Rand' },
	{ code: 'AED', label: 'UAE Dirham' },
	{ code: 'ILS', label: 'Israeli Shekel' },
	{ code: 'MXN', label: 'Mexican Peso' },
	{ code: 'RUB', label: 'Russian Ruble' },
	{ code: 'SAR', label: 'Saudi Riyal' },
	{ code: 'KRW', label: 'South Korean Won' },
	{ code: 'TWD', label: 'Taiwan Dollar' }
].map(currency => ({
	...currency,
	symbol: getSymbolFromCurrency(currency.code) || currency.code
}));

export default function FirstJourney() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { company } = useSelector(state => state.company);
	const { workplaces } = useSelector(state => state.workplace);
	const { contracts, journey } = useSelector(state => state.site);
	const [openNewSite, setOpenNewSite] = useState(true); // Start with dialog open
	const [newSiteData, setNewSiteData] = useState({
		name: '',
		address: '',
		city: '',
		postal_code: '',
		country: '',
		sqm: '',
		annual_rent: '',
		employees_to: '',
		longitude: '',
		latitude: '',
		currency: 'EUR'
	});
	const [selectedPath, setSelectedPath] = useState(null);
	const [map, setMap] = useState(null);
	const [marker, setMarker] = useState(null);
	const mapContainer = useRef(null);
	const mapInstanceRef = useRef(null);
	const [activeStep, setActiveStep] = useState(0);
	const [departments, setDepartments] = useState([]);
	const [businessContext, setBusinessContext] = useState({
		mission: company?.mission || '',
		vision: company?.vision || '',
		services: company?.services || [],
		key_functions: company?.key_functions || [],
		strategy: company?.strategy || '',
		strategic_timeline: company?.strategic_timeline || [],
		local_factors: company?.local_factors || []
	});

	const steps = ['Welcome', 'Create Site', 'Business Context', 'Departments', 'Create Project'];

	const handleNewSiteChange = (field) => (event) => {
		setNewSiteData({
			...newSiteData,
			[field]: event.target.value
		});
	};
	console.log('contracts f', contracts);
	console.log('business context', businessContext);
	const handleCreateNewSite = async () => {
		if (contracts?.length > 0) {
			// Update existing contract
			const url = `${process.env.REACT_APP_BACKEND_URL}/contracts/${contracts[0].id}/update`;
			try {
				await axios.put(url, {
					company_id: company.id,
					is_active: true,
					...newSiteData
				});
				await dispatch(getCompanyContracts(company));
			} catch (error) {
				console.error('Error updating contract:', error.message);
			}
		} else {
			// Create new contract
			await createContract(company.id, newSiteData);
		}

		setOpenNewSite(false);
		navigate('/journey/discover');
	};
	
	async function createPrjct() {
const contract = contracts[0];
		dispatch(createJourney({
			company_id: company.id,
			label: contract.name,
			contract_id: contract.id,
			statusSurvey: 'open',
			work_model: 100,
			employeePerspective: 100,
			sqmNeeded: contract.sqmNeed,
			occupancy: 100,
			occupancyPeak: 100,
			occupancyLow: 100,
			employees: contract.employees_to,
			sqm: contract.sqm,
			rent: contract.annual_rent,
			city: contract.city,
			address: contract.address,
			longitude: contract.longitude,
			latitude: contract.latitude,
			new_work_model: 100,
			country: contract.country,
			path: selectedPath
		}, workplaces));
		navigate('/journey/discover');
	}
		
	async function createContract(company_id, siteData = {}) {
		const url = `${process.env.REACT_APP_BACKEND_URL}/contracts/create`;
		try {
			await axios.post(url, {
				company_id,
				is_active: true,
				...siteData
			})
				.then((response) => {
					console.log(response.data);
					const contract = response.data;
					dispatch(getCompanyContracts(company));
				})
		} catch (error) {
			console.error(error.message);
		}
	}

	useEffect(() => {
		console.log('Component mounted');
		console.log('mapContainer:', mapContainer);
		console.log('mapContainer.current:', mapContainer.current);
	}, []); // Empty dependency array to run once on mount

	useEffect(() => {
		// Don't try to initialize if we're not on the correct step
		if (activeStep !== 1) return;

		const initMap = () => {
			console.log('Attempting to initialize map');
			console.log('mapContainer.current:', mapContainer.current);
			
			if (!mapContainer.current) {
				console.log('No container yet, trying again in 100ms');
				setTimeout(initMap, 100);
				return;
			}

			if (mapInstanceRef.current) {
				console.log('Map already initialized');
				return;
			}

			try {
				mapboxgl.accessToken = mapConfig;
				
				const initializeMap = new mapboxgl.Map({
					container: mapContainer.current,
					style: 'mapbox://styles/mapbox/streets-v11',
					center: [13.404954, 59.0686],
					zoom: 5,
					attributionControl: true
				});

				mapInstanceRef.current = initializeMap;
				console.log('Map initialized successfully');

				initializeMap.addControl(new mapboxgl.NavigationControl(), 'top-right');

				initializeMap.on('load', () => {
					setMap(initializeMap);
					console.log('Map loaded');
				});
			} catch (error) {
				console.error('Error initializing map:', error);
			}
		};

		initMap();

		return () => {
			if (mapInstanceRef.current) {
				mapInstanceRef.current.remove();
				mapInstanceRef.current = null;
			}
		};
	}, [activeStep]); // Add activeStep as dependency

	useEffect(() => {
		if (!map || !newSiteData.address || !newSiteData.city) return;

		// Create a debounced version of the geocoding call
		const timeoutId = setTimeout(() => {
			const searchAddress = `${newSiteData.address}, ${newSiteData.city}`;

			fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchAddress)}.json?access_token=${mapboxgl.accessToken}`)
				.then(response => response.json())
				.then(data => {
					if (data.features && data.features.length > 0) {
						const feature = data.features[0];
						const [lng, lat] = feature.center;

						// Extract postal code and country from context
						const postalCode = feature.context?.find(c => c.id.startsWith('postcode'))?.text || '';
						const country = feature.context?.find(c => c.id.startsWith('country'))?.text || '';

						// Update all location-related data in state
						setNewSiteData(prev => ({
							...prev,
							longitude: lng,
							latitude: lat,
							postal_code: postalCode,
							country: country
						}));

						// Remove existing marker if it exists
						if (marker) {
							marker.remove();
						}

						// Create and set new marker
						const newMarker = new mapboxgl.Marker()
							.setLngLat([lng, lat])
							.addTo(map);

						setMarker(newMarker);

						map.flyTo({
							center: [lng, lat],
							zoom: 15
						});
					}
				})
				.catch(error => console.error('Error geocoding address:', error));
		}, 500); // Add 500ms debounce

		// Cleanup function
		return () => clearTimeout(timeoutId);

	}, [map, newSiteData.address, newSiteData.city]); // Remove marker from dependencies

	useEffect(() => {
		if (!journey) return;
		console.log('journey', journey);
		navigate('/journey/discover');
		dispatch(getCompany(company.id));
	}, [journey]);

	useEffect(() => {
		if (contracts?.length > 0) {
			const contract = contracts[0];
			setNewSiteData({
				name: contract.name || '',
				address: contract.address || '',
				city: contract.city || '',
				postal_code: contract.postal_code || '',
				country: contract.country || '',
				sqm: contract.sqm || '',
				annual_rent: contract.annual_rent || '',
				employees_to: contract.employees_to || '',
				longitude: contract.longitude || '',
				latitude: contract.latitude || '',
				currency: contract.currency || 'EUR'
			});
		}
	}, [contracts]);

	const handleNext = async () => {
		switch (activeStep) {
			case 0:
				// Simply move to next step
				setActiveStep((prevStep) => prevStep + 1);
				break;
				
			case 1:
				// Create or update contract
				try {
					if (contracts?.length > 0) {
						// Update existing contract
						const url = `${process.env.REACT_APP_BACKEND_URL}/contracts/${contracts[0].id}/update`;
						await axios.put(url, {
							company_id: company.id,
							is_active: true,
							...newSiteData
						});
					} else {
						// Create new contract
						await createContract(company.id, newSiteData);
					}
					await dispatch(getCompanyContracts(company));
					setActiveStep((prevStep) => prevStep + 1);
				} catch (error) {
					console.error('Error creating/updating contract:', error);
					// You might want to show an error message to the user here
				}
				break;
				
			case 2:
				// Update company with business context and hasContext flag
				try {
					await dispatch(updateCompany(company.id, {
						mission: businessContext.mission,
						vision: businessContext.vision,
						services: businessContext.services,
						key_functions: businessContext.key_functions,
						strategy: businessContext.strategy,
						strategic_timeline: businessContext.strategic_timeline,
						local_factors: businessContext.local_factors,
						hasContext: true
					}));
					setActiveStep((prevStep) => prevStep + 1);
				} catch (error) {
					console.error('Error updating business context:', error);
				}
				break;
				
			case 3:
				// Update company with departments flag
				try {
					await dispatch(updateCompany(company.id, {
						hasDepartments: true
					}));
					setActiveStep((prevStep) => prevStep + 1);
				} catch (error) {
					console.error('Error updating departments:', error);
				}
				break;
				
			default:
				setActiveStep((prevStep) => prevStep + 1);
		}
	};

	const handleBack = () => {
		setActiveStep((prevStep) => prevStep - 1);
	};

	const handleBusinessContextChange = (field, value) => {
		setBusinessContext(prev => ({
			...prev,
			[field]: value
		}));
	};

	const handleTimelineChange = (index, field, value) => {
		const newTimeline = [...businessContext.strategic_timeline];
		newTimeline[index] = { ...newTimeline[index], [field]: value };
		handleBusinessContextChange('strategic_timeline', newTimeline);
	};

	const handleFactorsChange = (index, field, value) => {
		const newFactors = [...businessContext.local_factors];
		newFactors[index] = { ...newFactors[index], [field]: value };
		handleBusinessContextChange('local_factors', newFactors);
	};

	const handleAddItem = (field) => (event) => {
		if (event.key === 'Enter' && event.target.value.trim()) {
			handleBusinessContextChange(field, [...businessContext[field], event.target.value.trim()]);
			event.target.value = '';
		}
	};

	const handleDeleteItem = (field, indexToDelete) => {
		handleBusinessContextChange(
			field,
			businessContext[field].filter((_, index) => index !== indexToDelete)
		);
	};

	const isStep1Valid = () => {
		return Boolean(
			newSiteData.name &&
			newSiteData.address &&
			newSiteData.city &&
			newSiteData.sqm &&
			newSiteData.annual_rent &&
			newSiteData.employees_to
		);
	};

	const isStep2Valid = () => {
		return Boolean(businessContext.mission?.trim());
	};

	const isStep3Valid = () => {
		return departments.length > 0 || company?.companydepartments?.length > 0;
	};

	const getStepHelperText = () => {
		switch (activeStep) {
			case 1:
				if (!isStep1Valid()) {
					return "Please fill in all required site details to continue";
				}
				return "All site details complete - ready to proceed";
				
			case 2:
				if (!isStep2Valid()) {
					return "Please enter at least your company's mission statement to continue";
				}
				return "Business context ready - you can add more details or proceed";
				
			case 3:
				if (!isStep3Valid()) {
					return "Please add at least one department to continue";
				}
				return "Departments added - ready to create your project";
				
			case 4:
				if (!selectedPath) {
					return "Please select a transformation path to continue";
				}
				return "Ready to start your workplace journey";
				
			default:
				return "Click next to begin setting up your workplace";
		}
	};

	useEffect(() => {
		const determineInitialStep = () => {
			if (company?.hasDepartments && company?.hasContext && contracts?.length > 0) return 4;
			if (company?.hasContext && contracts?.length > 0) return 3;
			if (contracts?.length > 0) return 2;
			return 0;
		};

		setActiveStep(determineInitialStep());
	}, [contracts, company]); // Dependencies that affect the step determination

	const handleDepartmentsChange = (newDepartments) => {
		console.log('newDepartments', newDepartments);
		setDepartments(newDepartments);
	};

	const calculatePrice = (baseRate, discount = 0, pathKey) => {
		// Use contract's employee count if available, otherwise use newSiteData
		const employees = contracts?.[0]?.employees_to || newSiteData.employees_to || 0;
		const originalPrice = baseRate * employees;
		// Apply 100% discount for first project
		const discountedPrice = 0; // Set to 0 since it's the first project (100% discount)
		
		return {
			original: `€${originalPrice.toLocaleString()}`,
			total: '€0',
			calculation: `${employees} employees × €${baseRate} per employee`,
			discount: '100% discount applied (First project)'
		};
	};

	// Define basePaths with pricing
	const basePaths = {
		survey: {
			path: 'Survey',
			title: "Survey Only",
			description: "Get insights from your employees through our comprehensive workplace survey",
			price: "Basic",
			baseRate: 25,
			discount: 1, // Set to 1 for 100% discount
			phases: {
				discover: [
					{ icon: <Groups />, label: "Employee Perspective" }
				]
			}
		},
		light: {
			path: 'Light',
			title: "Pure Right Size",
			description: "Focused approach on space optimization and essential workplace elements",
			price: "Light",
			baseRate: 50,
			discount: 1, // Set to 1 for 100% discount
			phases: {
				discover: [
					{ icon: <Groups />, label: "Single Perspective" }
				],
				define: [
					{ icon: <AspectRatio />, label: "Dimensioning" }
				],
				design: [
					{ icon: <Chair />, label: "Work Settings" },
					{ icon: <SupportAgent />, label: "Support Areas" },
					{ icon: <Domain />, label: "Business Specific" }
				]
			}
		},
		plus: {
			path: 'Plus',
			title: "Future Workplace",
			description: "Complete workplace transformation project with comprehensive analysis and implementation",
			price: "Plus",
			baseRate: 100,
			discount: 1, // Set to 1 for 100% discount
			phases: {
				discover: [
					{ icon: <Groups />, label: "Employee Perspective" },
					{ icon: <Business />, label: "Management Perspective" }
				],
				define: [
					{ icon: <Domain />, label: "Organisation" },
					{ icon: <WorkOutline />, label: "Work Model" },
					{ icon: <LocationOn />, label: "Location" },
					{ icon: <AccountBalance />, label: "Budget" },
					{ icon: <AspectRatio />, label: "Dimensioning" },
					{ icon: <CompareArrows />, label: "Way of Working" }
				],
				design: [
					{ icon: <Chair />, label: "Work Settings" },
					{ icon: <SupportAgent />, label: "Support Areas" },
					{ icon: <Domain />, label: "Business Specific" },
					{ icon: <Dashboard />, label: "Zoning" },
					{ icon: <EjectOutlined />, label: "Sustainability" },
					{ icon: <Settings />, label: "Technical Specs" }
				]
			}
		}
	};

	// Create paths with pricing
	const [paths] = useState(
		Object.entries(basePaths).reduce((acc, [key, path]) => ({
			...acc,
			[key]: {
				...path,
				pricing: calculatePrice(path.baseRate, path.discount)
			}
		}), {})
	);

	// Add journey object
	console.log('journey', journey);
	return (
		<>
			<DashboardNavbar />
			<RootStyle>
				<Container maxWidth="lg">
					<Card sx={{ p: 5, pt: 0 }}>
						<Stepper
							activeStep={activeStep}
							sx={{ mb: 4 }}
							alternativeLabel
						>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							))}
						</Stepper>

						{activeStep === 0 && (
							<Grid container spacing={3} alignItems="center">
								<Grid item xs={12} md={6}>
									<Stack spacing={3}>
										<SoftTypography variant="h3" fontWeight="bold" textGradient color="primary">
											Welcome to Your Workplace Journey
										</SoftTypography>
										<SoftTypography variant="body1">
											We're excited to help you optimize your workplace. As a new user,
											you'll receive your first project completely free of charge.
										</SoftTypography>
										<SoftTypography variant="body1">
											In the next few steps, we'll guide you through:
										</SoftTypography>
										<List sx={{ py: 2 }}>
											<ListItem sx={{ py: 1 }}>
												<ListItemIcon>
													<Avatar sx={{ bgcolor: 'primary.main' }}>1</Avatar>
												</ListItemIcon>
												<ListItemText primary="Setting up your site details" />
											</ListItem>
											<ListItem sx={{ py: 1 }}>
												<ListItemIcon>
													<Avatar sx={{ bgcolor: 'primary.main' }}>2</Avatar>
												</ListItemIcon>
												<ListItemText primary="Defining your business context" />
											</ListItem>
											<ListItem sx={{ py: 1 }}>
												<ListItemIcon>
													<Avatar sx={{ bgcolor: 'primary.main' }}>3</Avatar>
												</ListItemIcon>
												<ListItemText primary="Organizing your departments" />
											</ListItem>
										</List>
										<SoftTypography variant="body1" sx={{ mt: 2 }}>
											Once completed, you'll be ready to start surveying the needs of your teams and create an optimized workplace strategy.
										</SoftTypography>
									</Stack>
								</Grid>
								<Grid item xs={12} md={6}>
									<Box
										component="img"
										src={JourneyIllustration}
										sx={{
											width: '100%',
											maxWidth: 400,
											mx: 'auto',
											display: 'block'
										}}
									/>
								</Grid>
							</Grid>
						)}

						{activeStep === 1 && (
							<>
								<SoftTypography variant="h3" fontWeight="bold" textGradient color="primary">
									Create your site
								</SoftTypography>
								<Grid container spacing={3} sx={{ pt: 2 }}>
									<Grid item xs={6}>
										<Box
											ref={mapContainer}
											sx={{
												height: '400px',
												width: '100%',
												borderRadius: '10px',
												overflow: 'hidden',
												border: '1px solid #ccc',
												position: 'relative',
												display: activeStep === 1 ? 'block' : 'none', // Hide instead of unmounting
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<Stack spacing={2}>
											<SoftBox mb={2}>
												<SoftInput
													placeholder="Site Name"
													value={newSiteData.name}
													onChange={handleNewSiteChange('name')}
												/>
											</SoftBox>
											<SoftBox mb={2}>
												<SoftInput
													placeholder="Address"
													value={newSiteData.address}
													onChange={handleNewSiteChange('address')}
												/>
											</SoftBox>
											<SoftBox mb={2}>
												<SoftInput
													placeholder="City"
													value={newSiteData.city}
													onChange={handleNewSiteChange('city')}
												/>
											</SoftBox>
											<SoftBox mb={2}>
												<SoftInput
													type="number"
													placeholder="Square Meters"
													value={newSiteData.sqm}
													onChange={handleNewSiteChange('sqm')}
												/>
											</SoftBox>
											<SoftBox mb={2}>
												<Stack direction="row" spacing={1}>
													<SoftInput
														type="number"
														placeholder="Annual Rent"
														value={newSiteData.annual_rent}
														onChange={handleNewSiteChange('annual_rent')}
														sx={{ flexGrow: 1 }}
														startAdornment={
															<InputAdornment 
																position="start" 
																sx={{ 
																	mr: 1,
																	'& .MuiTypography-root': {
																		color: 'text.secondary',
																		position: 'relative',
																		left: '8px'  // Add some left padding
																	}
																}}
															>
																{getSymbolFromCurrency(newSiteData.currency)}
															</InputAdornment>
														}
													/>
													<Select
														value={newSiteData.currency}
														onChange={handleNewSiteChange('currency')}
														sx={{ minWidth: 100 }}
													>
														{currencies.map((currency) => (
															<MenuItem key={currency.code} value={currency.code}>
																{`${currency.code} (${getSymbolFromCurrency(currency.code)})`}
															</MenuItem>
														))}
													</Select>
												</Stack>
											</SoftBox>
											<SoftBox mb={2}>
												<SoftInput
													type="number"
													placeholder="Number of Employees"
													value={newSiteData.employees_to}
													onChange={handleNewSiteChange('employees_to')}
												/>
											</SoftBox>
										</Stack>
									</Grid>
								</Grid>
							</>
						)}

						{activeStep === 2 && (
							<>
								<SoftTypography variant="h3" fontWeight="bold" textGradient color="primary">
									Update Business Context
								</SoftTypography>
								<Grid container spacing={3} sx={{ pt: 2 }}>
									{/* Mission & Vision */}
									<Grid item xs={12} md={6}>
										<SoftBox mb={2}>
											<SoftTypography variant="subtitle2" fontWeight="medium" mb={1}>
												Mission Statement
											</SoftTypography>
											<SoftInput
												multiline
												rows={3}
												placeholder="Enter company mission..."
												value={businessContext.mission}
												onChange={(e) => handleBusinessContextChange('mission', e.target.value)}
											/>
										</SoftBox>
									</Grid>

									<Grid item xs={12} md={6}>
										<SoftBox mb={2}>
											<SoftTypography variant="subtitle2" fontWeight="medium" mb={1}>
												Vision Statement
											</SoftTypography>
											<SoftInput
												multiline
												rows={3}
												placeholder="Enter company vision..."
												value={businessContext.vision}
												onChange={(e) => handleBusinessContextChange('vision', e.target.value)}
											/>
										</SoftBox>
									</Grid>

									{/* Services & Functions */}
									<Grid item xs={12} md={6}>
										<SoftBox mb={2}>
											<SoftTypography variant="subtitle2" fontWeight="medium" mb={1}>
												Key Services
											</SoftTypography>
											<Stack spacing={1}>
												<SoftInput
													placeholder="Type and press Enter to add service..."
													onKeyPress={handleAddItem('services')}
												/>
												<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
													{businessContext.services.map((service, index) => (
														<Chip
															key={index}
															label={service}
															onDelete={() => handleDeleteItem('services', index)}
															color="primary"
															variant="outlined"
														/>
													))}
												</Box>
											</Stack>
										</SoftBox>
									</Grid>

									<Grid item xs={12} md={6}>
										<SoftBox mb={2}>
											<SoftTypography variant="subtitle2" fontWeight="medium" mb={1}>
												Key Functions
											</SoftTypography>
											<Stack spacing={1}>
												<SoftInput
													placeholder="Type and press Enter to add function..."
													onKeyPress={handleAddItem('key_functions')}
												/>
												<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
													{businessContext.key_functions.map((func, index) => (
														<Chip
															key={index}
															label={func}
															onDelete={() => handleDeleteItem('key_functions', index)}
															color="primary"
															variant="outlined"
														/>
													))}
												</Box>
											</Stack>
										</SoftBox>
									</Grid>

									{/* Strategy */}
									{/* 	<Grid item xs={12}>
										<SoftBox mb={2}>
											<SoftTypography variant="subtitle2" fontWeight="medium" mb={1}>
												Strategic Timeline
											</SoftTypography>
											{businessContext.strategic_timeline.map((milestone, index) => (
												<Box key={index} sx={{ mb: 2, display: 'flex', gap: 2 }}>
													<SoftInput
														placeholder="Date"
														value={milestone.date}
														onChange={(e) => handleTimelineChange(index, 'date', e.target.value)}
														sx={{ width: '20%' }}
													/>
													<SoftInput
														placeholder="Title"
														value={milestone.title}
														onChange={(e) => handleTimelineChange(index, 'title', e.target.value)}
														sx={{ width: '30%' }}
													/>
													<SoftInput
														placeholder="Description"
														value={milestone.description}
														onChange={(e) => handleTimelineChange(index, 'description', e.target.value)}
														sx={{ flexGrow: 1 }}
													/>
													<IconButton
														onClick={() => {
															const newTimeline = businessContext.strategic_timeline.filter((_, i) => i !== index);
															handleBusinessContextChange('strategic_timeline', newTimeline);
														}}
														color="error"
													>
														<DeleteOutline />
													</IconButton>
												</Box>
											))}
											<SoftButton
												variant="outlined"
												color="primary"
												onClick={() => {
													const newTimeline = [...businessContext.strategic_timeline,
													{ date: '', title: '', description: '', completed: false }
													];
													handleBusinessContextChange('strategic_timeline', newTimeline);
												}}
											>
												Add Milestone
											</SoftButton>
										</SoftBox>
									</Grid> */}

									{/* Local Factors */}
									{/* <Grid item xs={12}>
										<SoftBox mb={2}>
											<SoftTypography variant="subtitle2" fontWeight="medium" mb={1}>
												Local Market Factors
											</SoftTypography>
											{businessContext.local_factors.map((factor, index) => (
												<Box key={index} sx={{ mb: 2, display: 'flex', gap: 2 }}>
													<SoftInput
														placeholder="Factor Name"
														value={factor.name}
														onChange={(e) => handleFactorsChange(index, 'name', e.target.value)}
														sx={{ width: '30%' }}
													/>
													<SoftInput
														placeholder="Description"
														value={factor.description}
														onChange={(e) => handleFactorsChange(index, 'description', e.target.value)}
														sx={{ flexGrow: 1 }}
													/>
													<SoftInput
														type="number"
														placeholder="Score (1-5)"
														value={factor.score}
														onChange={(e) => handleFactorsChange(index, 'score', Math.min(5, Math.max(1, parseInt(e.target.value) || 1)))}
														sx={{ width: '15%' }}
													/>
													<IconButton
														onClick={() => {
															const newFactors = businessContext.local_factors.filter((_, i) => i !== index);
															handleBusinessContextChange('local_factors', newFactors);
														}}
														color="error"
													>
														<DeleteOutline />
													</IconButton>
												</Box>
											))}
											<SoftButton
												variant="outlined"
												color="primary"
												onClick={() => {
													const newFactors = [...businessContext.local_factors, { name: '', description: '', score: 1 }];
													handleBusinessContextChange('local_factors', newFactors);
												}}
											>
												Add Factor
											</SoftButton>
										</SoftBox>
									</Grid> */}
								</Grid>
							</>
						)}

						{activeStep === 3 && (
							<Departments
								title="List company departments"
								deps={company?.companydepartments}
								onChange={handleDepartmentsChange}
								isFirstJourney={true}
							/>
						)}

						{activeStep === 4 && (
							<>
								<SoftTypography variant="h3" fontWeight="bold" textGradient color="primary">
									Select Your Transformation Path
								</SoftTypography>
								<Grid container spacing={3} sx={{ pt: 2 }}>
									<Grid item xs={12}>
										<Stack direction="row" spacing={3}>
											{Object.entries(paths).map(([key, path]) => (
												<StyledCard
													key={key}
													selected={selectedPath === key}
													hasSelection={selectedPath !== null}
													journey={journey}
													basePaths={basePaths}
													pathKey={key}
													onClick={() => setSelectedPath(key)}
													sx={{ 
														width: '33.33%', 
														display: 'flex', 
														flexDirection: 'column', 
														position: 'relative'
													}}
												>
													<PriceTag
														journey={journey}
														basePaths={basePaths}
														pathKey={key}
													>
														{path.price}
													</PriceTag>
													
													<CardContent>
														<Box sx={{ flex: 1 }}>
															<SoftTypography variant="h5" gutterBottom fontWeight="bold" color="primary">
																{path.title}
															</SoftTypography>
															<SoftTypography variant="body2" color="text.secondary" paragraph>
																{path.description}
															</SoftTypography>

															{Object.entries(path.phases).map(([phase, features]) => (
																<Box key={phase} sx={{ mb: 2 }}>
																	<SoftTypography
																		variant="subtitle2"
																		color="primary"
																		sx={{
																			textTransform: 'uppercase',
																			fontWeight: 'bold',
																			mb: 1
																		}}
																	>
																		<Timeline sx={{ mr: 1, verticalAlign: 'middle' }} />
																		{phase}
																	</SoftTypography>
																	<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
																		{features.map((feature, index) => (
																			<FeatureChip
																				key={index}
																				icon={feature.icon}
																				label={feature.label}
																				size="small"
																			/>
																		))}
																	</Box>
																</Box>
															))}
														</Box>
														<Divider />
														<PriceSection>
															{path.pricing.original && (
																<OriginalPrice>
																	{path.pricing.original}
																</OriginalPrice>
															)}
															{path.pricing.total && (
																<SoftTypography variant="h4" color="primary" fontWeight="bold">
																	{path.pricing.total}
																</SoftTypography>
															)}
															{path.pricing.calculation && (
																<PriceCalculation>
																	<SoftTypography variant="caption" color="text.secondary">
																		{path.pricing.calculation}
																	</SoftTypography>
																	{path.pricing.discount && (
																		<SoftTypography variant="caption" color="success.main" fontWeight="medium">
																			{path.pricing.discount}
																		</SoftTypography>
																	)}
																</PriceCalculation>
															)}
														</PriceSection>
													</CardContent>
												</StyledCard>
											))}
										</Stack>
										
										<Box sx={{ 
											display: 'flex', 
											alignItems: 'center', 
											gap: 1, 
											mt: 3, 
											p: 2, 
											borderRadius: 2,
											backgroundColor: (theme) => alpha(theme.palette.info.main, 0.1)
										}}>
											<Info color="info" />
											<SoftTypography variant="body2" color="info.main">
												You can upgrade your path at any time during your project. Your progress will be preserved.
											</SoftTypography>
										</Box>
									</Grid>
								</Grid>
							</>
						)}

						<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
							<SoftButton
								variant="gradient"
								color="secondary"
								onClick={handleBack}
								disabled={activeStep === 0}
							>
								Back
							</SoftButton>
							{activeStep === 0 && (
								<SoftButton
									variant="outlined"
									color="secondary"
									onClick={() => navigate(-1)}
								>
									Use in demo mode
								</SoftButton>
							)}
							{activeStep === steps.length - 1 ? (
								<SoftButton
									variant="gradient"
									color="success"
									onClick={createPrjct}
									endIcon={<ArrowForwardIcon />}
									disabled={!selectedPath} // Add this line to disable button when no path is selected
								>
									Create Project & Start Journey
								</SoftButton>
							) : (
								<SoftButton
									variant="gradient"
									color="primary"
									onClick={handleNext}
									endIcon={<ArrowForwardIcon />}
									disabled={
										(activeStep === 1 && !isStep1Valid()) ||
										(activeStep === 2 && !isStep2Valid()) ||
										(activeStep === 3 && !isStep3Valid())
									}
								>
									Next
								</SoftButton>
							)}
						</Box>
						<SoftTypography 
							variant="body2" 
							color={
								(activeStep === 1 && !isStep1Valid()) ||
								(activeStep === 2 && !isStep2Valid()) ||
								(activeStep === 3 && !isStep3Valid())
									? "error.main"
									: "success.main"
							}
							sx={{ mt: 2, textAlign: 'center' }}
						>
							{getStepHelperText()}
						</SoftTypography>
					</Card>
				</Container>
			</RootStyle>
		</>
	);
} 
import { LoadingButton } from '@mui/lab';
import {
  Alert, Card, Grid,
  Snackbar,
  Stack, Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import * as Yup from 'yup';
import { geoConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';
import LoadingScreen from '../../LoadingScreen';
import SoftBox from '../../SoftBox';
import SoftInput from '../../SoftInput';
import SoftTypography from '../../SoftTypography';

// ----------------------------------------------------------------------

Geocode.setApiKey(geoConfig);

const ENERGY_LEVELS = [{ value: 'Breem', label: 'Breem' }, { value: 'Breem Gold', label: 'Breem Gold' }];
// ----------------------------------------------------------------------

export default function PremisesForm({ space }) {

  const { translate } = useLocales();
  const [coordinates, setCoordinates] = useState('');
  const [sqmall, setSqmall] = useState(0);
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const NewMissionSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
  });

  const formik = useFormik({
    initialValues: { ...space },
    validationSchema: NewMissionSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await updateFields(values);
        setSubmitting(false);
        enqueueSnackbar('Post success', { variant: 'success' });
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  useEffect(() => {
    console.log(values);
    const { upperLevel, streetLevel, basement, office, other, storage } = values;
    const sum = parseInt(upperLevel || 0) + parseInt(streetLevel || 0) + parseInt(basement || 0) + parseInt(other || 0) + parseInt(office || 0) + parseInt(storage || 0);
    console.log(sum);
    setSqmall(sum);

  }, [values]);

  useEffect(() => {
    if (!values.address || !values.city) return;
    console.log(values.address, values.city);
    const coords = getCoordinates(values.address, values.city);
  }, [values.address, values.city]);
  const updateFields = () => {
    console.log(values);
    const url = `${process.env.REACT_APP_BACKEND_URL}/spaces/update/${space?.id}`;
    console.log(values.name);
    console.log(space.id);
    if (values.name.length === 0 || space.id.length === 0) return;
    const body = { ...values, sqm: sqmall, upperLevel: parseInt(values.upperLevel), streetLevel: parseInt(values.streetLevel), basement: parseInt(values.basement), office: parseInt(values.office), other: parseInt(values.other), storage: parseInt(values.storage), zip, country, lat: coordinates.lat, lng: coordinates.lng };
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })

      .then((response) => {
        console.log('UPDATED');
        setOpenSnack(true);
        //  props.history.push(`/places/${response.id}`);
        //  setComplete(true);
      })
      .catch((error) => console.log(error.message));
  };
  // TODO: BOILERPLATE FOR FIND ITEMS! const ll = landlords?.find((x) => x.id === values.landlordId.toString());

  async function getCoordinates(add, cit) {
    console.log(add, cit);
    try {
      const enhan = await Geocode.fromAddress(add + '%20' + cit);
      console.log(enhan);
      const location = await enhan.results[0].geometry.location;
      const addcomp = enhan.results[0].address_components;
      const country = addcomp.filter((comp) => comp.types[0] === 'country');
      const zip = addcomp.filter((comp) => comp.types[0] === 'postal_code');
      console.log('zip', zip);
      setCoordinates(location);
      setZip(zip?.long_name);
      setCountry(country?.long_name);
    } catch (error) {
      // If any of the awaited promises was rejected, this catch block
      // would catch the rejection reason
      return error;
    }
  }
  function handleEdi() {
    console.log('Starting updating fields');
    updateFields(values);
  }
  if (!space) return <LoadingScreen />;
  return (
    <>

      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Grid container spacing={3}>

                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="subtitle1"> {translate('Workplace')} </Typography>
                      <LoadingButton
                        onClick={handleEdi}
                        variant="contained"
                        color="success"
                        loading={isSubmitting}
                      >
                        {translate('Save')}
                      </LoadingButton>

                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1"> {translate('Workplace configuration today')} </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <SoftBox p={1} pl={0}>
                      <SoftTypography variant="h6">Open desks</SoftTypography>
                    </SoftBox>
                    <SoftInput
                      fullWidth
                      placeholder={translate('address')}
                      {...getFieldProps('address')}
                      error={Boolean(touched.address && errors.adddress)}
                      helperText={touched.address && errors.address}
                    />
                  </Grid>
                  {/*  {coordinates?.lat && (
                    <Grid item xs={12}>
                      <SpaceMap space={space} coords={coordinates} height={400} />
                    </Grid>
                  )} */}

                  {/* <Grid item xs={12}>
                    <Typography variant="subtitle1"> {translate('Fritext Marknad')} </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      value={values.markettext}
                      onChange={(event, newValue) => {
                        setFieldValue('markettext', newValue);
                        handleEdi();
                      }}
                      renderInput={(params) => <TextField {...params} label={translate('Fritext')} />}
                    />
                  </Grid> */}
                </Grid>
              </Card>
              <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                  {translate('updatesSaved')}
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
}

import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Auth0Client } from '@auth0/auth0-spa-js';
//
import { auth0Config } from '../config';

// ----------------------------------------------------------------------

let auth0Client = null;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, claims } = action.payload;
    return { ...state, isAuthenticated, isInitialized: true, user, claims };
  },
  AUTHCHECK: (state, action) => {
    const { user, claims } = action.payload;
    return { ...state, isAuthenticated: true, user, claims };
  },
  LOGIN: (state, action) => {
    const { user, claims } = action.payload;
    return { ...state, isAuthenticated: true, user, claims };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'auth0',
  login: () => Promise.resolve(),
  authcheck: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        auth0Client = new Auth0Client({
          clientId: auth0Config.clientId,
          domain: auth0Config.domain,
          redirect_uri: window.location.origin
        });
        await auth0Client.checkSession();

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
          const user = await auth0Client.getUser();
          let claims = await auth0Client.getIdTokenClaims();  
          dispatch({
            type: 'INITIALIZE',
            payload: { isAuthenticated, user, claims }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: { isAuthenticated, user: null }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: { isAuthenticated: false, user: null }
        });
      }
    };

    initialize();
  }, []);

  const login = async () => {
    await auth0Client.loginWithPopup();
    const isAuthenticated = await auth0Client.isAuthenticated();

    if (isAuthenticated) {
      const user = await auth0Client.getUser();
      let claims = await auth0Client.getIdTokenClaims();  
      dispatch({ type: 'LOGIN', payload: { user, claims } });
    }
  };

  const authcheck = async () => {
    const isAuthenticated = await auth0Client.isAuthenticated();
    const user = await auth0Client.getUser();
   // console.log('user', user);
    if (isAuthenticated) {
      const user = await auth0Client.getUser();
      let claims = await auth0Client.getIdTokenClaims();  
      dispatch({ type: 'AUTHCHECK', payload: { user, claims } });
    }
  };
  const logout = () => {
    auth0Client.logout();
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = () => {};
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'auth0',
        user: {
          id: state?.user?.sub,
          photoURL: state?.user?.picture,
          email: state?.user?.email,
          displayName: state?.user?.name,
          lastName: state?.user?.family_name,
          firstName: state?.user?.given_name,
          locale: state?.user?.locale,
          role: state?.user?.role,
          internal: state?.claims?.internal,
          hasSupply: state?.claims?.has_supply,
          hasGoogle: state?.claims?.has_google,
          hasAdvisory: state?.claims?.has_advisory,
          hasTurnovers: state?.claims?.has_turnovers,
          hasDistance: state?.claims?.has_distance,
          hasDemand: state?.claims?.has_demand,
          hasAnalyses: state?.claims?.has_analyses,
          companyHubspot: state.claims?.company_id,
          companyName: state.claims?.company_name,
          isSubscribed: state.claims?.is_subscribed,
          companyNotVerified: state.claims?.companyNotVerified,
          hasCompletedSurvey: state.claims?.hasCompletedSurvey,
          isTest: state.claims?.istest,
          isAdmin: state.claims?.isAdmin,
          journey_id: state.claims?.journey_id,
          dummy: state.claims?.dummy,
          isLight: state.claims?.isLight,
        },
        login,
        authcheck,
        logout,
        resetPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

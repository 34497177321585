/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router components
import { Link, useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import { Badge, Box, Stack, Step, StepLabel, Stepper, Typography, Switch, Chip, ToggleButton, ToggleButtonGroup, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import SoftBox from "../../../components/SoftBox";
import SoftSelect from "../../../components/SoftSelect";
import SoftTypography from "../../../components/SoftTypography";
import useAuth from "../../../hooks/useAuth";
import useOffSetTop from '../../../hooks/useOffSetTop';
import AccountPopover from "../../../layouts/dashboard/AccountPopover";
import {
  setMiniSidenav,
  setOpenConfigurator, setTransparentNavbar, useSoftUIController
} from "../../../softcontext";
import NotificationItem from "../../Items/NotificationItem";
import {
  navbar,
  navbarContainer, navbarDesktopMenu, navbarRow
} from "./styles";
import { getCompany } from "../../../redux/slices/company";
import axios from "axios";
import { getJourneySpacebricks } from "../../../redux/slices/workplace";
import PathSelectionDialog from "../../../components/PathSelectionDialog";
import { updateJourney } from "../../../redux/slices/site";
import { alpha } from "@mui/material/styles";

function DashboardNavbar({ absolute, light, isMini, name, onCompanyChange }) {
  const { user, login, logout } = useAuth();
  const dispatchredux = useDispatch();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const { company, companies } = useSelector((state) => state.company);
  const { journey, contract, contracts } = useSelector((state) => state.site);
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const navigate = useNavigate();

  // Add this mapping for steps
  const pathToStep = {
    'discover': 0,
    'define': 1,
    'design': 2,
    'decide': 3
  };

  // Get the current step based on the path
  const currentStep = pathToStep[route[route.length - 1]] || 0;

  // Reverse mapping for navigation
  const stepToPath = {
    0: 'journey/discover',
    1: 'journey/define',
    2: 'journey/design',
    3: 'journey/decide'
  };

  const handleStepClick = (index) => {
    const path = stepToPath[index];
    if (path) {
      navigate(`/${path}`);
    }
  };

  useEffect(() => {
    if (journey) {
      if (!journey.path) {
        setOpenPathDialog(true);
      }
    }
  }, [journey]);

  useEffect(() => {
    // Setting the navbar type
    /*   if (fixedNavbar) {
        setNavbarType("sticky");
      } else {
        setNavbarType("static");
      } */

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const [notifications, setNotifications] = useState([]);

  const getDaysUntil = (date) => {
    if (!date) return null;
    const today = new Date();
    const targetDate = new Date(date);
    return Math.ceil((targetDate - today) / (1000 * 60 * 60 * 24));
  };

  useEffect(() => {
    const expiringContracts = contracts.filter(contract => {
      const daysUntilExpiry = getDaysUntil(contract.through);
      const daysUntilNotice = getDaysUntil(contract.notificationDeadline);

      // Only include contracts with valid data
      if (!contract.name && !contract.label) return false;

      // Notify if contract expires in 30 days or less or if notice deadline is critical
      return (daysUntilExpiry <= 30) || (daysUntilNotice <= 30);
    });
    setNotifications(expiringContracts);
  }, [contracts]);

  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  async function updateUser(userData) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/companies/updateuser`;
    try {
      const response = await axios.put(url, {
        user_id: user.id,
        ...userData
      });
      if (response.data?.user_metadata?.company_id) {
        console.log('company id', response.data.user_metadata.company_id);
        handleLoginAuth0();
      }
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
  }

  const handleCompanyChange = (event) => {
    console.log('event', event);
    const selectedCompany = companies.find(c => c.id === event.value);
    updateUser({
      id: selectedCompany.id,
      name: selectedCompany.name,
      journey_id: journey.id
    })
      .then(() => {
        dispatchredux(getCompany(selectedCompany.id))
          .then(() => {
            dispatchredux({ type: 'workplace/clearSpacebricks' }); // You'll need to add this action

            // Then fetch new data for the new company
            if (journey?.id) {
              dispatchredux(getJourneySpacebricks({ journey_id: journey.id }));
            }
            //console.log('got spacebricks, reloading', spacebricks);
            // Finally reload the page
            // window.location.reload();
          });
      });
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {notifications.map((contract, index) => {
        const daysUntilExpiry = getDaysUntil(contract.through);
        const daysUntilNotice = getDaysUntil(contract.notificationDeadline);
        const isExpiring = daysUntilExpiry <= 30;

        return (
          <NotificationItem
            key={contract.id || index}
            image={<Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>warning</Icon>}
            title={[
              `Contract: ${contract.name || contract.label}`,
              `Status: ${isExpiring ? 'Expiring Soon' : 'Critical Notice'}`
            ]}
            date={`${isExpiring ? daysUntilExpiry : daysUntilNotice} days remaining`}
            onClick={handleCloseMenu}
          />
        );
      })}
    </Menu>
  );

  // Add new state for light mode (easy mode)
  const [isLightMode, setIsLightMode] = useState(user?.isLight || false);

  // Add handler for light mode toggle
  const handleLightModeToggle = async () => {
    try {
      const newMode = !isLightMode;
      setIsLightMode(newMode);

      await updateUser({
        id: user.companyHubspot,
        user_id: user.id,
        isLight: newMode
      });

      handleLoginAuth0();
    } catch (error) {
      console.error('Error updating easy mode:', error);
    }
  };

  const [openSettings, setOpenSettings] = useState(false);

  const handlePathChange = (value) => {
    // Handle path change
  };

  // Add new state for dialog
  const [openPathDialog, setOpenPathDialog] = useState(false);

  // Add handler for path selection
  const handlePathSelection = async ({ path, mode }) => {
    try {
      // Update user settings
      dispatchredux(updateJourney(journey.id, {
        path: path,
        mode: mode
      }));
      // Close dialog
      setOpenPathDialog(false);
    } catch (error) {
      console.error('Error updating path settings:', error);
    }
  };

  // Add this function to check if we're in a journey path
  const isJourneyPath = () => {
    return route[0] === 'journey' && ['discover', 'define', 'design', 'decide'].includes(route[1]);
  };

  return (
    <>
      <AppBar
        position={"sticky"}
        color="inherit"
        sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
      >
        <Toolbar sx={(theme) => ({
          ...navbarContainer(theme),
          position: 'relative'
        })}>
          <SoftBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => ({
              ...navbarRow(theme, { isMini }),
              position: 'relative',
              left: miniSidenav ? '-9rem' : 0,
              transition: 'left 200ms ease-in-out',
              display: 'flex',
              alignItems: 'center'
            })}
          >
            <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
            <SoftBox pr={1}>
              {journey?.id && (
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    height: '100%',
                    alignItems: 'center',
                    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.05),
                    borderRadius: 2,
                    px: 2,
                    py: 0.75,
                    border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
                      transform: 'translateY(-1px)',
                      boxShadow: 1
                    }
                  }}
                >
                  <Stack direction="row" spacing={1.5} alignItems="center">
                    <Tooltip title="Journey Settings">
                      <IconButton
                        size="small"
                        onClick={() => setOpenPathDialog(true)}
                        sx={{
                          color: 'primary.main',
                          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                          '&:hover': {
                            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2)
                          }
                        }}
                      >
                        <Icon fontSize="small">settings</Icon>
                      </IconButton>
                    </Tooltip>
                    <Stack>
                      <SoftTypography
                        variant="button"
                        fontWeight="bold"
                        color="primary"
                      >
                        {contract?.name}
                      </SoftTypography>
                      <SoftTypography
                        variant="caption"
                        color="text.secondary"
                        sx={{ lineHeight: 1 }}
                      >
                        Active Project
                      </SoftTypography>
                    </Stack>
                  </Stack>

                  <Stack direction="row" spacing={1} alignItems="center">
                    <Chip
                      icon={
                        <Icon sx={{ fontSize: '1.1rem !important' }}>
                          {journey?.path === 'extensive' ? 'route' : 'speed'}
                        </Icon>
                      }
                      label={journey?.path === 'plus' ? 'Plus' : journey?.path === 'light' ? 'Light' : 'Survey'}
                      size="small"
                      sx={{
                        backgroundColor: (theme) => journey?.path === 'plus' 
                          ? alpha(theme.palette.info.main, 0.1)
                          : journey?.path === 'light' ? alpha(theme.palette.success.main, 0.1) : alpha(theme.palette.primary.main, 0.1),
                        color: journey?.path === 'plus' ? 'info.main' : journey?.path === 'light' ? 'success.main' : 'primary.main',
                        '& .MuiChip-icon': {
                          color: 'inherit'
                        }
                      }}
                    />
                    {/* <Chip
                      icon={
                        <Icon sx={{ fontSize: '1.1rem !important' }}>
                          {journey?.mode === 'easy' ? 'auto_awesome' : 'psychology'}
                        </Icon>
                      }
                      label={journey?.mode === 'easy' ? 'Easy Mode' : 'Advanced'}
                      size="small"
                      sx={{
                        backgroundColor: (theme) => journey?.mode === 'easy'
                          ? alpha(theme.palette.warning.main, 0.1)
                          : alpha(theme.palette.secondary.main, 0.1),
                        color: journey?.mode === 'easy' ? 'warning.main' : 'secondary.main',
                        '& .MuiChip-icon': {
                          color: 'inherit'
                        }
                      }}
                    /> */}
                  </Stack>

                  <IconButton
                    size="small"
                    sx={{
                      ml: 'auto',
                      color: 'primary.main',
                      backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                      '&:hover': {
                        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2)
                      }
                    }}
                    component={Link}
                    to="/"
                  >
                    <Icon fontSize="small">chevron_right</Icon>
                  </IconButton>
                </Stack>
              )}
            </SoftBox>
            {/*           <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} name={name} />
 */}          </SoftBox>
          {isMini ? null : (
            <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
              <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                {isJourneyPath() && (

                  <Stepper
                    activeStep={currentStep}
                    alternativeLabel
                    sx={{
                      flexGrow: 3,
                      my: 0,
                      mt: -1.5,
                      height: 'fit-content',
                      alignItems: 'center',
                      minHeight: 'unset',
                      mx: 'auto',
                      width: '40%',
                      position: 'absolute',
                      left: '55%',
                      transform: 'translateX(-50%)',
                      zIndex: 0,
                      maxWidth: 'calc(100% - 500px)',
                      '& .MuiStepIcon-root': {
                        '&.Mui-active': {
                          color: '#DE63A1',
                          '& .MuiStepIcon-text': {
                            fill: '#fff'
                          }
                        },
                        '&.Mui-completed': {
                          color: '#5E064F'
                        },
                        color: alpha('#DE63A1', 0.4)
                      },
                      '& .MuiStepConnector-root': {
                        '& .MuiStepConnector-line': {
                          borderColor: alpha('#DE63A1', 0.3)
                        },
                        '&.Mui-active, &.Mui-completed': {
                          '& .MuiStepConnector-line': {
                            borderColor: '#DE63A1'
                          }
                        }
                      }
                    }}
                  >
                    {["Discover", "Define", "Design", "Decide"].map((label, index) => (
                      <Step 
                        key={label} 
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleStepClick(index)}
                      >
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                )}
              </Box>

              <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5, height: '100%' }}>
                {user?.isAdmin ? (
                  <SoftSelect
                    value={company?.id ? { label: company.name, value: company.id } : ''}
                    onChange={handleCompanyChange}
                    options={companies?.map((comp) => ({
                      label: comp.name,
                      value: comp.id
                    }))}
                    sx={{ minWidth: 200 }}
                  />
                ) : (
                  <Stack sx={{ display: 'flex', height: '100%' }}>
                    <SoftTypography
                      variant="button"
                      fontWeight="medium"
                    >
                      {user?.displayName}
                    </SoftTypography>

                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                      {user?.companyName}
                    </Typography>
                  </Stack>

                )}
              {/*   <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Icon sx={{ color: 'text.secondary', mr: 0.5 }}>auto_awesome</Icon>
                  <Switch
                    checked={isLightMode}
                    onChange={handleLightModeToggle}
                    color="primary"
                  />
                </Box> */}
                <AccountPopover company={company} />
                <IconButton onClick={handleOpenMenu}>
                  <Badge badgeContent={notifications.length} color="error">
                    <Icon>notifications</Icon>
                  </Badge>
                </IconButton>
                {renderMenu()}
              </Stack>
            </SoftBox>
          )}
        </Toolbar>
      </AppBar>

      <PathSelectionDialog
        open={openPathDialog}
        onClose={() => setOpenPathDialog(false)}
        onSave={handlePathSelection}
        journey={journey}
      />
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  name: '',
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  name: PropTypes.string,
  onCompanyChange: PropTypes.func,
};

export default DashboardNavbar;

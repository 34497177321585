import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setDemouse } from '../redux/slices/company';
import useAuth from '../hooks/useAuth';

export default function DemoGuard({ children }) {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (pathname.startsWith('/demo') || !user.companyHubspot) {
      dispatch(setDemouse(true));
    }
  }, [pathname, dispatch]);

  return children;
}
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { prospectMagic } from '../../utils/prospectMagic';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  contract: '',
  contracts: [],
  prospects: [],
  prospect: '',
  journey: '',
  journeys: [],
  searchareas: [],
  proposals: [],
  proposal: '',
  inquiries: [],
  inquiry: '',
  spaces: [],
  offers: [],
  offer: '',
};

const slice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getContractSuccess(state, action) {
      state.isLoading = false;
      console.log('contract', action.payload);
      state.contract = action.payload;
    },
    getContractsSuccess(state, action) {
      state.isLoading = false;
      state.contracts = action.payload;
      /*    if (!state.contract) {
           // TODO: UPDATE TO BE UNINAMIOUS
           state.contract = action.payload.find(contract => contract.id === state.contract.id);
         } */
    },
    // ----------------------------------------------------------------------

    // JOURNEYS

    // ----------------------------------------------------------------------
    getJourneysSuccess(state, action) {
      state.isLoading = false;
      state.journeysReceived = true;
      state.journeys = action.payload;

      if (!state.journey) {
        // TODO: UPDATE TO BE UNINAMIOUS
        state.journey = action.payload[0];
      }
    },
    getJourneySuccess(state, action) {
      state.isLoading = false;
      state.journey = action.payload;
    },

    onSetJourney(state, action) {
      const journeyNew = action.payload;
      state.journey = journeyNew;
    },

    getProspectSuccess(state, action) {
      console.log(' got prospect', action.payload);
      const magic = prospectMagic(action.payload)
      console.log('magic', magic);
      const pr = action.payload.prospect;
      const obj = { ...pr, magic };
      console.log('obj prospect', obj);
      state.prospect = obj;
      state.isLoading = false;
    },
    getJourneySpaceSuccess(state, action) {
      state.isLoading = false;
      console.log('action.payload', action.payload);
      const { spaces } = action.payload;
      if (spaces.length > 0) {
        state.space = action.payload.spaces[0]
      } else {
        state.hasNoSpace = true;
      };
    },

    getSpacesSuccess(state, action) {
      state.isLoading = false;
      console.log('spaces', action.payload);
      state.spaces = action.payload.spaces;
    },
    getProposalsSuccess(state, action) {
      state.isLoading = false;
      state.proposals = action.payload;
    },
    getProposalSuccess(state, action) {
      state.isLoading = false;
      state.proposal = action.payload;
    },
    getInquiriesSuccess(state, action) {
      state.isLoading = false;
      state.inquiries = action.payload;
    },
    getInquirySuccess(state, action) {
      state.isLoading = false;
      state.inquiry = action.payload;
    },
    getSearchareasSuccess(state, action) {
      state.isLoading = false;
      state.searchareas = action.payload;
    },
    getMyOffersSuccess(state, action) {
      state.isLoading = false;
      state.offers = action.payload;
    },
    getOfferSuccess(state, action) {
      state.isLoading = false;
      state.offer = action.payload;
    },

    // ----------------------------------------------------------------------

    // PROSPECTS

    // ----------------------------------------------------------------------
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { setJourney, setProspect, setContract } = slice.actions;

// ----------------------------------------------------------------------
export function getSpaces() {
  console.log('get spaces');
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/spaces/index/`);
      dispatch(slice.actions.getSpacesSuccess(response.data));
      console.log('got spaces', response.data);
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getContract(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/contracts/contract/${id}`);
      console.log('contract received', response.data);
      dispatch(slice.actions.getContractSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getCompanyContracts(company) {
  console.log('company', company);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/contracts/companyindex/${company.id}`);
      console.log('contracts received', response.data);
      const contracts = response.data.filter(contract => contract.is_active);
      console.log('contracts', contracts);
      if (company.last_contract_id && contracts.length > 0) {
        dispatch(getContract(company.last_contract_id))
      } else if (contracts.length > 0) {
        dispatch(getContract(contracts[0].id))
      }
      console.log('setting contracts', contracts);
      dispatch(slice.actions.getContractsSuccess(contracts));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function updateContract(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/contracts/update/${id}`, params);

    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// JOURNEYS
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getJourneys() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/journeys/index/`);
      dispatch(slice.actions.getJourneysSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getCompanyJourneys(company_id) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/journeys/companyjourneys/${company_id}`);
      console.log('journeys', response.data);
      dispatch(slice.actions.getJourneysSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateJourney(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('uodate journey', params);
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/journeys/update/${id}`, params);
      dispatch(getJourney(response.data.id));
      console.log('journey updated', response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJourneySpace(id) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/journeys/journeyspace/${id}`);
      dispatch(slice.actions.getJourneySpaceSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJourney(id) {
  return async (dispatch) => {
    console.log('get journey', id);
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/journeys/journey/${id}`, {
        params: { id }
      });
      dispatch(slice.actions.getJourneySuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createJourney(params, workplaces) {
  console.log('createa journey', params);
  return async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/journeys/create`;
      const response = await axios.post(url, params);
      console.log('journey created,', response.data); // Assuming you want to log each successful response
      dispatch(slice.actions.getJourneySuccess(response.data));
      dispatch(getCompanyContracts(params.company_id));
      dispatch(getContract(params.contract_id));
      workplaces.forEach((e) => {
        const obj = { label: e.name, category: e.activity_category, soundenv: e.soundenv, workplace_id: e.id, sqm: e.size, ppl: e.ppl, fte: e.fte, qty: 1, roundedqty: 1, journey_id: response.data.id, variant: 'workplace' };
        console.log('create needbrick', obj);
        dispatch(createNeedbrick(obj));
      });
      dispatch(createDepartment({ company_id: response.data.company_id, journey_id: response.data.id, name: 'All' }));

    } catch (error) {
      console.error(error.message); // Log the error message if the request fails
    }
  };
}
// ----------------------------------------------------------------------
// PROSPECTS
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function updateProspect(id, params, workplaces, activityprofiles, workplacedistributions) {
  console.log('update prospect', id, params);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('uodate prospect', params);
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/prospects/update/${id}`, params);
      console.log('prospect updated', response.data);
      dispatch(getContract(response.data.contract_id));
      dispatch(getProspect(response.data.id, workplaces, activityprofiles, workplacedistributions));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getProspect(id, workplaces, activityprofiles, workplacedistributions) {
  return async (dispatch) => {
    console.log('get prospect', id);
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/prospects/prospect/${id}`);

      const respi = { prospect: response.data, workplaces, activityprofiles, workplacedistributions };
      dispatch(slice.actions.getProspectSuccess(respi));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
/// SEARCH AREA

export function createSearchArea(params) {
  console.log('createa search area', params);
  return async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/searchareas/create`;
      const response = await axios.post(url, params);
      console.log('search area created,', response.data); // Assuming you want to log each successful response
      dispatch(slice.actions.getSearchareasSuccess(response.data));
    } catch (error) {
      console.error(error.message); // Log the error message if the request fails
    }
  };
}

// ----------------------------------------------------------------------

export function getSearchareas(params) {
  console.log('start getting searchareas', params);
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/searchareas/journeyindex/${params.journey_id}`, { params });
      console.log('searchareas', response.data);
      dispatch(slice.actions.getSearchareasSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMyProposals(company_id) {
  console.log('start getting proposals', company_id);
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/proposals/myproposals/${company_id}`);
      console.log('proposals', response.data);
      dispatch(slice.actions.getProposalsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProposal(id) {
  console.log('start getting proposal', id);
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/proposals/proposal/${id}`);
      console.log('proposal', response.data);
      dispatch(slice.actions.getProposalSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateProposal(id, params) {
  console.log('update proposal', id, params);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('uodate proposal', params);
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/proposals/update/${id}`, params);
      console.log('proposal updated', response.data);
      dispatch(getProposal(response.data.id));
      dispatch(getMyProposals(response.data.company_tenant_id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMyInquiries(company_id) {
  console.log('start getting inquiries', company_id);
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/inquiries/myinquiries/${company_id}`);
      console.log('inquiries', response.data);
      dispatch(slice.actions.getInquiriesSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInquiry(id) {
  console.log('start getting inquiry', id);
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/inquiries/inquiry/${id}`);
      console.log('inquiry', response.data);
      dispatch(slice.actions.getInquirySuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateInquiry(id, params) {
  console.log('update inquiry', id, params);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('uodate inquiry', params);
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/inquiries/update/${id}`, params);
      console.log('inquiry updated', response.data);
      dispatch(getInquiry(response.data.id));
      dispatch(getMyInquiries(response.data.company_id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMyOffers(company_id) {
  console.log('start getting offers', company_id);
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/offers/myoffers/${company_id}`);
      console.log('offers', response.data);
      dispatch(slice.actions.getMyOffersSuccess(response.data.offers));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOffer(id) {
  console.log('start getting offer', id);
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/offers/offer/${id}`);
      console.log('offer', response.data);
      dispatch(slice.actions.getOfferSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateOffer(id, params) {
  console.log('update offer', id, params);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('uodate offer', params);
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/offers/update/${id}`, params);
      console.log('offer updated', response.data);
      dispatch(getOffer(response.data.id));
      dispatch(getMyOffers(response.data.company_id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createNeedbrick(params) {
  console.log('create needbrick',);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('create needbrick', params);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/needbricks/create`, params);
      console.log('needbrick created', response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createDepartment(params) {
  console.log('create department',);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('create department', params);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/departments/create`, params);
      console.log('department created', response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
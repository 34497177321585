import { Box } from "@mui/material";
import axios from "axios";
import Geocode from 'react-geocode';
import { useDispatch } from "react-redux";
import CsvUploader from "../../../utils/CsvUploader3.tsx";
import SoftTypography from "../../../components/SoftTypography/index.js";
import { geoConfig } from "../../../config.js";
import useAuth from "../../../hooks/useAuth.js";
import { getCompanyContracts } from "../../../redux/slices/site.js";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

Geocode.setApiKey(geoConfig);
// ----------------------------------------------------------------------

export default function BatchContracts({ onComplete }) {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { company } = useSelector((state) => state.company);
  const [data, setData] = useState([]);
  const handleUpload = (d) => {
    setData(d);
  }
  useEffect(() => {
    if (!data.length) return;
    createContracts(data);
  }, [data]);

  async function createContracts(data) {
    const url = "${process.env.REACT_APP_BACKEND_URL}/contracts/create";

    await Promise.all(data.map(async (rawContract) => {
      const id = rawContract[0];
      const name = rawContract[1];
      const address = rawContract[2];
      const postal_code = rawContract[3];
      const city = rawContract[4];
      const country = rawContract[5];
      const sqm = rawContract[6];
      const annual_rent = rawContract[7];
      const employees_to = rawContract[8];
      const latitude = rawContract[9];
      const longitude = rawContract[10];
      const contract = {
        id,
        company_id: user.companyHubspot,
        name,
        postal_code,
        city,
        address,
        country,
        sqm,
        annual_rent,
        employees_to,
        latitude,
        longitude,
      };
      const geocodedContract = await handleGeocode(contract);
      console.log("i", geocodedContract);

      try {
        await axios.post(url, geocodedContract);
      } catch (error) {
        console.error('Error creating contract:', error);
      }
    }));

    dispatch(getCompanyContracts(company));
    onComplete();
  }

  const handleGeocode = async (contract) => {
    if (contract.latitude && contract.longitude) return contract;
    
    let searchAddress = '';
    if (contract.address && contract.city) {
      searchAddress = `${contract.address}, ${contract.city}`;
    } else if (contract.postal_code && contract.city) {
      searchAddress = `${contract.postal_code} ${contract.city}`;
    } else if (contract.postal_code) {
      searchAddress = contract.postal_code;
    }

    if (!searchAddress) return contract;

    try {
      const response = await Geocode.fromAddress(searchAddress);
      const { lat, lng } = response.results[0].geometry.location;
      return { ...contract, latitude: lat, longitude: lng };
    } catch (error) {
      console.error('Geocoding error:', error);
      return contract;
    }
  }


  //RENDER
  return (
    <Box sx={{ p: 3 }}>
      <SoftTypography>
        {"Upload batch list of sites."}
      </SoftTypography>
      <SoftTypography variant="caption" sx={{ mb: 2 }}>
        Please use the{' '}
        <a 
          href="https://mindretail.sharepoint.com/:x:/s/MindRetailAB/EcaBtlyXhwVFnPc18tUDRwwBGw99_2uqGqL_VC-vj2fFdg?e=EzUd9p" 
          target="_blank" 
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline' }}
        >
          provided template
        </a>
        {' '}to upload a list of sites.
      </SoftTypography>
      <CsvUploader onUpload={handleUpload} />
    </Box>
  );
}

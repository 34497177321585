/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
function collapseItem(theme, ownerState) {
  const { palette, transitions, breakpoints, boxShadows, borders, functions, warn } = theme;
  const { active, transparentSidenav, completed, isFuture } = ownerState;

  const { dark, white, text, transparent, success, warning } = palette;
  const { xxl } = boxShadows;
  const { borderRadius } = borders;
  const { pxToRem } = functions;

  return {
    background: active && transparentSidenav ? white.main : transparent.main,
    color: isFuture ? 'grey' : active ? text.main : white.main,
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: `${pxToRem(10.8)} ${pxToRem(12.8)} ${pxToRem(10.8)} ${pxToRem(16)}`,
    margin: `0 ${pxToRem(16)}`,
    borderRadius: borderRadius.md,
    cursor: !isFuture && "pointer",
    userSelect: "none",
    whiteSpace: "nowrap",
    boxShadow: active && transparentSidenav ? xxl : "none",
    [breakpoints.up("xl")]: {
      boxShadow: () => {
        if (active) {
          return transparentSidenav ? xxl : "none";
        }

        return "none";
      },
      transition: transitions.create("box-shadow", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter,
      }),
    },
  };
}

function collapseIconBox(theme, ownerState) {
  const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
  const { active, transparentSidenav, sidenavColor, completed, status, isReady, reqReady, isFuture } = ownerState;
  const { white, info, light, gradients, success, warning, error } = palette;
  const { md } = boxShadows;
  const { borderRadius } = borders;
  const { pxToRem } = functions;
  return {
    background: () => {
     /*  if (active && (completed || status === 'success')) {
        return success.dark;
      } else if (active && status === 'warning') {
        return warning.dark
      } else if (active && status === 'error') {
        return error.dark
      } else if (status === 'warning') {
        return warning.light
      } else if (status === 'error') {
        return error.light
      } else if (completed || status === 'success') {
        return success.dark;
      } else */ if (active) {
        return sidenavColor === "default" ? info.main : palette[sidenavColor].main;
      }

      return light.main;
    },
    minWidth: pxToRem(32),
    minHeight: pxToRem(32),
    borderRadius: borderRadius.md,
    display: "grid",
    placeItems: "center",
    boxShadow: md,
    transition: transitions.create("margin", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    [breakpoints.up("xl")]: {
      background: () => {
        let background;

        /* if (active && (completed || status === 'success')) {
          return success.dark;
        } else if (active && status === 'warning') {
          return warning.dark
        } else if (active && status === 'error') {
          return error.dark
        } else if (status === 'warning') {
          return warning.light
        } else if (status === 'error') {
          return error.light
        } else if (completed || status === 'success') {
          return success.main;
        } else  */if (!active) {
          background = transparentSidenav ? white.main : light.main;
        } else if (sidenavColor === "default") {
          background = info.main;
        } else if (sidenavColor === "warning") {
          background = gradients.warning.main;
        } else {
          background = palette[sidenavColor].main;
        }

        return background;
      },
    },

    "& svg, svg g": {
      fill: active ? white.main : gradients.dark.state,
    },
  };
}

const collapseIcon = ({ palette: { white, gradients } }, { active, isFuture }) => ({
  color: isFuture ? 'lightgrey' : (active ? white.main : gradients.dark.state),
});

function collapseText(theme, ownerState) {
  const { typography, transitions, breakpoints, functions } = theme;
  const { miniSidenav, transparentSidenav, active, isFuture, isReady, reqReady } = ownerState;
  const { size, fontWeightMedium, fontWeightRegular } = typography;
  const { pxToRem } = functions;

  return {
    marginLeft: pxToRem(12.8),
    color: isFuture || (reqReady && !isReady) ? 'lightgrey' : 'white',
    textGradient: isReady,
    [breakpoints.up("xl")]: {
      opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
      maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
      marginLeft: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(12.8),
      transition: transitions.create(["opacity", "margin"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),

      textTransform: "capitalize",
    },

    "& span": {
      fontWeight: active ? fontWeightMedium : fontWeightRegular,
      fontSize: size.sm,
      lineHeight: 0,
    },
  };
}

function collapseArrow(theme, ownerState) {
  const { palette, typography, transitions, breakpoints, functions } = theme;
  const { noCollapse, transparentSidenav, miniSidenav, open } = ownerState;

  const { dark, gradients } = palette;
  const { size } = typography;
  const { pxToRem, rgba } = functions;

  return {
    fontSize: `${size.md} !important`,
    fontWeight: 700,
    marginBottom: pxToRem(-1),
    transform: open ? "rotate(0)" : "rotate(-180deg)",
    color: open ? dark.main : rgba(gradients.dark.state, 0.0),
    transition: transitions.create(["color", "transform", "opacity"], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      display:
        noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
          ? "none !important"
          : "block !important",
    },
  };
}

export { collapseItem, collapseIconBox, collapseIcon, collapseText, collapseArrow };
